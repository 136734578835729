import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Input,
  PLATFORM_ID,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { ItemParam } from '@app/core/models/ItemParam';
import { GeoSearch } from '../geo-search/geo-search.model';
import { ParamsDomain } from './../../domain/params.domain';
import { SearchDomain } from './../../domain/search.domain';
import { L10nLocale, L10N_LOCALE, L10nTranslationService } from 'angular-l10n';
import {
  QRDialogConfig,
  QrDialogComponent,
} from '../qr-dialog/qr-dialog.component';
import { Icon } from '@app/core/models/icon.model';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SkeletonLoaderComponent } from '../ui/skeleton-loader/skeleton-loader.component';
import { QrIconComponent } from '@app/components/qr-icon/qr-icon.component';
import { QrDropdownComponent } from '../qr-dropdown/qr-dropdown.component';
import { QrDropdownSelectComponent } from '../qr-dropdown-select/qr-dropdown-select.component';
import { QrFilterPrice } from '../../core/models/qr-filter-price';
import { LocationsSearcherComponent } from '../locations-searcher/locations-searcher.component';
import { Subscription } from 'rxjs';
import { ButtonSaveSearchComponent } from '../../pages/public/listing-list/components/button-save-search/button-save-search.component';
import { QRButtonComponent } from '../ui/qr-button/qr-button.component';
import { QRButton } from '../../core/models/qr-button.model';
import { QRIcon } from '../../core/models/qr-icon.model';
import { environment } from '@base/environments/environment';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';

export interface Dropdown {
  name: string;
  isOpen: boolean;
  referenceDivPosition: string;
  padding: number;
  width: number;
  height: number;
}

export interface DropdownSelect {
  placeholder: string;
  isOpen: boolean;
}

@Component({
  selector: 'qr-list-listing-sub-navbar',
  templateUrl: './qr-list-listing-sub-navbar.component.html',
  styleUrls: ['./qr-list-listing-sub-navbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SkeletonLoaderComponent,
    QrIconComponent,
    QrDropdownComponent,
    QrDialogComponent,
    QrDropdownSelectComponent,
    LocationsSearcherComponent,
    ButtonSaveSearchComponent,
    QRButtonComponent,
    MatTooltipModule,
    MatRippleModule,
  ],
})
export class QrListListingSubNavbarComponent implements OnInit, OnDestroy {
  // Diálogo: Filtros
  dialogFilters: QRDialogConfig = {
    isDialogOpen: false,
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'list.clean-filter',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'list.seeResults',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'filters-list',
    isTopbarVisible: true,
    isBotbarVisible: true,
    isEntrepreneurship: false,
  };

  @Input() isEntrepreneurship: boolean = false;
  @Output() onChangeView: EventEmitter<string> = new EventEmitter<string>();

  isOnlyEntrepreneurshipResults: boolean = false;
  isOnlyEntrepreneurshipResultsSubscription: Subscription | undefined;
  filterCountSubscription: Subscription | undefined;

  // Price options
  priceOptions = [
    {
      value: '',
      text: 'Precio',
    },
  ];

  priceSelected = '';

  // Property type options
  propertyTypeOptions = [
    {
      value: '',
      text: 'Todos',
    },
  ];

  propertyTypeSelected = '';

  // Operation type options
  operationTypeOptions = [
    {
      value: '',
      text: 'Todos',
    },
  ];

  operationTypeSelected = '';

  dropdownSelectPropertyType: DropdownSelect = {
    placeholder: 'list.propertyType',
    isOpen: false,
  }; // Seleccionar desplegable: Tipo de propiedad

  dropdownPropertyType: Dropdown = {
    name: 'filter-property-type',
    isOpen: false,
    referenceDivPosition: '',
    padding: 0,
    width: 328,
    height: 436,
  }; // Desplegable: Tipo de propiedad

  dropdownSelectOperation: DropdownSelect = {
    placeholder: 'list.operationType',
    isOpen: false,
  };

  // Desplegable: Etapa de emprendimiento

  dropdownSelectStage: DropdownSelect = {
    placeholder: 'list.stage',
    isOpen: false,
  };

  // Seleccionar desplegable: Operación

  dropdownOperation: Dropdown = {
    name: 'filter-operation',
    isOpen: false,
    referenceDivPosition: '',
    padding: 8,
    height: 234,
    width: 248,
  };

  // Etapa emprendimientos
  dropdownStage: Dropdown = {
    name: 'filter-stage',
    isOpen: false,
    referenceDivPosition: '',
    padding: 8,
    height: 290,
    width: 248,
  }; // Desplegable: Operación

  /////////// ORDER BY
  dropdownSelectOrderBy: DropdownSelect = {
    placeholder: 'list.orderBy',
    isOpen: false,
  }; // Seleccionar desplegable: Operación

  dropdownOrderBy: Dropdown = {
    name: 'filter-orderBy',
    isOpen: false,
    referenceDivPosition: '',
    padding: 16,
    height: 100,
    width: 248,
  }; // Desplegable: OrderBy
  //////////

  dropdownSelectPrice: DropdownSelect = {
    placeholder: 'list.price',
    isOpen: false,
  }; // Seleccionar desplegable: Precio

  dropdownPrice: Dropdown = {
    name: 'filter-price',
    isOpen: false,
    referenceDivPosition: '',
    padding: 16,
    height: 168,
    width: 448,
  }; // Desplegable: Precio

  public buttonFilters: QRButton = {
    id: 'button-filters',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'list.filters',
    icon: QRIcon.NAME_FILTER,
  };

  public readonly NODE: string = environment.node;

  public readonly NODE_ECUADOR: string = Constants.NODE_ECUADOR;

  public dataOperation$: Promise<ItemParam[]>;
  public dataListingsTypes$: Promise<ItemParam[]>;
  public dataCurrencyTypes$: Promise<ItemParam[]>;
  public dataOrderBy$: Promise<ItemParam[]>;
  public dataStage$: Promise<ItemParam[]>;

  iconList: Icon = {
    name: Icon.NAME_MODE_VIEW_LIST,
    color: Icon.COLOR_GREY_WOLF,
  };

  iconGrid: Icon = {
    name: Icon.NAME_MODE_VIEW_GRID,
    color: Icon.COLOR_GREY_WOLF,
  };

  iconMap: Icon = {
    name: Icon.NAME_MODE_VIEW_MAP,
    color: Icon.COLOR_GREY_WOLF,
  };

  readonly LISTINGS_VIEW_MODE_LIST: string = Constants.LISTINGS_VIEW_MODE_LIST;
  readonly LISTINGS_VIEW_MODE_GRID: string = Constants.LISTINGS_VIEW_MODE_GRID;
  readonly LISTINGS_VIEW_MODE_MAP: string = Constants.LISTINGS_VIEW_MODE_MAP;

  readonly DROPDOWN_ID_PROPERTY_TYPE: string = 'select-property-type';
  readonly DROPDOWN_ID_OPERATION_TYPE: string = 'select-operation';
  readonly DROPDOWN_ID_STAGE: string = 'select-stage';
  readonly DROPDOWN_ID_PRICE: string = 'select-price';

  isGridViewModeEnable: boolean | null = isPlatformBrowser(this.platformId)
    ? window.innerWidth > 1154
    : null;

  isBrowser: boolean;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isGridViewModeEnable = window.innerWidth > 1154;
      if (
        !this.isGridViewModeEnable &&
        this.searchDomain.viewMode === this.LISTINGS_VIEW_MODE_GRID
      ) {
        this.viewModeChange(this.LISTINGS_VIEW_MODE_LIST);
      }
    }
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain,
    @Inject(PLATFORM_ID) private platformId: any,
    private translation: L10nTranslationService
  ) {
    this.dataOperation$ = this.paramsDomain.getOperationsTypes();
    this.dataListingsTypes$ = this.paramsDomain.getListingsTypes();
    this.dataCurrencyTypes$ = this.paramsDomain.getCurrenciesTypes();
    this.dataOrderBy$ = this.paramsDomain.getOrdersBy();
    this.dataStage$ = this.paramsDomain.getStage();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.viewModeChange(this.searchDomain.viewMode); // Definir en que modo de vista se encuentra
    this.dialogFilters.isEntrepreneurship = this.isEntrepreneurship;
    this.buttonFilters.value =
      'Filtros (' + this.searchDomain.filterCount + ')'; // Actualizar texto de botón de filtros, donde figura cant. de filtros aplicados
    this.setTypeOfResult();
    this.setFilterCount();
  }

  ngOnDestroy(): void {
    this.isOnlyEntrepreneurshipResultsSubscription?.unsubscribe();
    this.filterCountSubscription?.unsubscribe();
  }

  setTypeOfResult(): void {
    this.isOnlyEntrepreneurshipResultsSubscription =
      this.searchDomain.isOnlyEntrepreneurshipResults$.subscribe(
        (isOnlyEntrepreneurshipResults: boolean) => {
          this.isOnlyEntrepreneurshipResults = isOnlyEntrepreneurshipResults;
        }
      );
  }

  dataOrderBy(value: number[]): void {
    if (value != null) {
      this.searchDomain.filterOrderBy = [...value];
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  setFilterCount(): void {
    this.filterCountSubscription =
      this.searchDomain.filterCountResponse$.subscribe(count => {
        this.dialogFilters.configButtonRight!.text = count
          ? this.translation.translate('list.see') +
            ' ' +
            count +
            ' ' +
            this.translation.translate('list.results')
          : 'list.seeResults';
      });
  }

  viewModeChange(mode: string): void {
    this.iconList = { ...this.iconList, color: Icon.COLOR_GREY_WOLF };
    this.iconGrid = { ...this.iconGrid, color: Icon.COLOR_GREY_WOLF };
    this.iconMap = { ...this.iconMap, color: Icon.COLOR_GREY_WOLF };
    switch (mode) {
      case this.LISTINGS_VIEW_MODE_LIST:
        this.iconList = { ...this.iconList, color: Icon.COLOR_WHITE };
        break;
      case this.LISTINGS_VIEW_MODE_GRID:
        this.iconGrid = { ...this.iconGrid, color: Icon.COLOR_WHITE };
        break;
      case this.LISTINGS_VIEW_MODE_MAP:
        this.iconMap = { ...this.iconMap, color: Icon.COLOR_WHITE };
        this.isEntrepreneurship
          ? this.searchDomain.searchResultMap(true)
          : this.searchDomain.searchResultMap();
        break;
    }
    this.onChangeView.emit(mode);
  }

  filterUbicationChanged(value: GeoSearch[]): void {
    if (value != null) {
      this.searchDomain.filterGeoList = value;
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  filterListingTypeChanged(value: number[]): void {
    if (value != null) {
      this.searchDomain.filterListingType = [...value];
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  filterListingTypeUpdateData(value: number[]): void {
    if (value != null) {
      this.searchDomain.filterListingType = [...value];
    }
  }

  filterOperationChanged(value: number[]): void {
    if (value != null) {
      this.searchDomain.filterOperation = [...value];
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  filterStageChanged(value: number[]): void {
    if (value && value.length) {
      this.searchDomain.filterStage = [...value];
      if (this.searchDomain.isFilterChangeEntre()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    } else {
      this.searchDomain.filterStage = [0];
      this.searchDomain.windowLocationHrefEmpre();
    }
  }

  filterPriceChanged(value: QrFilterPrice): void {
    if (value != null) {
      this.searchDomain.filterPrice = value.clone();
      if (this.searchDomain.isFilterChange()) {
        this.searchDomain.page = 0;
        if (this.isEntrepreneurship) {
          this.searchDomain.windowLocationHrefEmpre();
        } else {
          this.searchDomain.windowLocationHref();
        }
      }
    }
  }

  /*
	openFilters: Abrir diálogo de filtros
	*/
  openFilters(): void {
    this.dialogFilters.isDialogOpen = true; // Diálogo visible
    //this.dialogFilters.data = this.filters; // Actualizo listado de filtros
    this.dialogFilters = Object.assign({}, this.dialogFilters);
  }

  /*
	toggleDropdown: Toggle de dropdowns de filtros de navbar 
	*/
  toggleDropdown(e: string): void {
    switch (e) {
      case this.DROPDOWN_ID_PROPERTY_TYPE:
        this.dropdownPropertyType.isOpen = !this.dropdownPropertyType.isOpen;
        this.dropdownPropertyType = {
          ...this.dropdownPropertyType,
          referenceDivPosition: e,
        };
        break;
      case this.DROPDOWN_ID_OPERATION_TYPE:
        this.dropdownOperation.isOpen = !this.dropdownOperation.isOpen;
        this.dropdownOperation = {
          ...this.dropdownOperation,
          referenceDivPosition: e,
        };
        break;
      case this.DROPDOWN_ID_STAGE:
        this.dropdownStage.isOpen = !this.dropdownStage.isOpen;
        this.dropdownStage = {
          ...this.dropdownStage,
          referenceDivPosition: e,
        };
        break;
      case this.DROPDOWN_ID_PRICE:
        this.dropdownPrice.isOpen = !this.dropdownPrice.isOpen;
        this.dropdownPrice = {
          ...this.dropdownPrice,
          referenceDivPosition: e,
        };
        break;
    }
  }

  /*
	dialogFiltersButtonRight: Al seleccionar 'Aplicar' en diálogo filtros
	*/
  dialogFiltersButtonRight(): void {
    this.dialogFilters.isDialogOpen = false;

    if (this.isEntrepreneurship) {
      this.searchDomain.windowLocationHrefEmpre();
    } else {
      this.searchDomain.windowLocationHref();
    }
  }

  clearAllFilters(): void {
    this.searchDomain.resetAll();
    if (this.isEntrepreneurship) {
      this.searchDomain.windowLocationHrefEmpre();
    } else {
      this.searchDomain.windowLocationHref();
    }
  }
}
