<div class="qr-biography">
  <div class="qr-biography__header">
    <h2 class="qr-biography__title">
      {{ title | translate: locale.language | titlecase }}
    </h2>
  </div>
  <div
    class="qr-biography__description"
    [ngClass]="{
      'qr-biography__description--full-height': showExtraDescription,
    }">
    <p class="qr-biography__text">{{ data | translate: locale.language }}</p>
  </div>
  @if (!showExtraDescription && !hideButton) {
    <div class="qr-biography__button-container">
      <qr-button-with-icon
        (click)="toggleExtraDescription()"
        [config]="button"
        class="qr-biography__view-more-button">
      </qr-button-with-icon>
    </div>
  }
</div>
