import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  CUSTOM_ELEMENTS_SCHEMA,
  SimpleChanges,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../directive/swiper.directive';
import { imageFix } from '../../core/utils/image_fix';
import { ResizedImage } from '../../core/models/resized-photo.model';
import { ListingPhoto } from '../../core/models/listing-photo.model';

export class PhotosFullScreen {
  isOpen?: boolean = false;
  indexSelected: number = 0;
}

@Component({
  selector: 'qr-photos-fullscreen',
  templateUrl: './qr-photos-fullscreen.component.html',
  styleUrls: ['./qr-photos-fullscreen.component.scss'],
  standalone: true,
  imports: [QrIconButtonComponent, SwiperDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QrPhotosFullscreenComponent implements OnChanges {
  @ViewChild('photosSwiperFS') public photosSwiper?: ElementRef;
  @ViewChild('thumbnailSwiperFS') public thumbnailsSwiper?: ElementRef;
  @Input() options!: PhotosFullScreen;
  @Input() photos: ListingPhoto[] | undefined = [];
  @Output() onchange: EventEmitter<number> = new EventEmitter<number>();
  public resizedImages: ResizedImage[] = [];
  public readonly SWIPER_PHOTOS: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
      nextEl: '.overlay__button-next',
      prevEl: '.overlay__button-prev',
    },
    grabCursor: true,
    watchSlidesProgress: true,
  };

  public readonly SWIPER_THUMBNAILS: SwiperOptions = {
    spaceBetween: 8,
    slidesPerView: 3,
    centeredSlides: true,
    centeredSlidesBounds: false,
    breakpoints: {
      '600': {
        slidesPerView: 4,
      },
      '840': {
        slidesPerView: 6,
      },
    },
    grabCursor: true,
    watchSlidesProgress: true,
  };

  public readonly BUTTON_CLOSE = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  // Eventos de teclado
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key == 'Escape' && this.options.isOpen == true) {
      // Tecla ESC: Cierra Diálogo
      this.close();
    }
    if (event.key == 'ArrowRight' && this.options.isOpen == true) {
      this.photosSwiper?.nativeElement.swiper.slideNext();
    }
    if (event.key == 'ArrowLeft' && this.options.isOpen == true) {
      this.photosSwiper?.nativeElement.swiper.slidePrev();
    }
  }

  constructor(private utils: UtilsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] && changes['options'].currentValue?.isOpen) {
      this.resizePhotos();
      setTimeout(() => {
        this.slideTo(this.options.indexSelected);
        this.galleryIndexChange(this.options.indexSelected);
      }, 10);
    }
  }

  /*
  galleryIndexChange: Notifica a que index se movio el gallery y mueve el
  swiper de thumbnails a ese index
  */
  public galleryIndexChange(indexToMove: number): void {
    this.options.indexSelected = indexToMove;
    this.thumbnailsSwiper?.nativeElement.swiper.slideTo(
      this.options.indexSelected
    );
    this.updateSelectedThumbnail();
  }

  public thumbnailsIndexChange(indexToMove: number): void {
    this.options.indexSelected = indexToMove;
    this.photosSwiper?.nativeElement.swiper.slideTo(this.options.indexSelected);
    this.updateSelectedThumbnail();
  }

  /*
  slideTo: Se ejecuta al clickear una thumbnails y mueve el swiper de gallery
  a ese index
  */
  public slideTo(indexToMove: number): void {
    this.options.indexSelected = indexToMove;
    this.photosSwiper?.nativeElement.swiper.slideTo(this.options.indexSelected);
    this.updateSelectedThumbnail();
  }

  public close(): void {
    this.options.isOpen = false;
    this.onchange.emit(this.options.indexSelected);
  }

  public errorImg(event: any): void {
    event.target.src = imageFix.property;
  }

  /*
  updateSelectedThumbnail: Actualiza el estilo de la thumbnail seleccionada
  */
  private updateSelectedThumbnail(): void {
    const ELEMENTS: Element[] = Array.from(
      document.querySelectorAll('.selected')
    );
    for (const element of ELEMENTS) {
      element.classList.remove('selected');
    }
    const THUMBNAIL_SELECTED: HTMLElement | null = document.getElementById(
      'thumbnail-' + this.options.indexSelected
    );
    if (THUMBNAIL_SELECTED) THUMBNAIL_SELECTED.classList.add('selected');
  }

  private resizePhotos(): void {
    if (this.photos) {
      const isWatermarkAdded: boolean =
        environment.node == Constants.NODE_ECUADOR;
      this.resizedImages = this.photos.map((photo: ListingPhoto) => {
        return {
          jpgXS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.SMALL,
            Constants.DOCTYPE_JPG,
            isWatermarkAdded
          ),
          webpXS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.SMALL,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          jpgS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.AUTO,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          webpS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.AUTO,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          jpgL: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.AUTO,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          webpL: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.AUTO,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
        };
      });
    }
  }
}
