import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MENU } from '../menu';
import { Constants } from '../../core/constants/constants';
import {
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10N_CONFIG,
  L10N_LOCALE,
  L10nTranslatePipe,
} from 'angular-l10n';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { UtilsService } from '@app/services/utils.service';
import { environment } from '@base/environments/environment';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Auth } from 'aws-amplify';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { MatMenuModule } from '@angular/material/menu';
import { QrIconButtonComponent } from '../../components/qr-icon-button/qr-icon-button.component';
import { QrMediaImgComponent } from '../../components/qr-media-img/qr-media-img.component';
import { SkeletonLoaderComponent } from '../../components/ui/skeleton-loader/skeleton-loader.component';
import { MatRippleModule } from '@angular/material/core';
import { MenuItem } from '../../core/models/menu.model';
import { QRButton } from '../../core/models/qr-button.model';
import { QRButtonComponent } from '../../components/ui/qr-button/qr-button.component';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    L10nTranslatePipe,
    QrIconButtonComponent,
    QrMediaImgComponent,
    RouterModule,
    MatMenuModule,
    QrMediaImgComponent,
    CommonModule,
    SkeletonLoaderComponent,
    QRButtonComponent,
    MatRippleModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  node = environment.node;
  readonly imageCloudfront: string = environment.imageCloudfront;
  readonly imageCountry: string = environment.node;
  readonly imageCloudfrontAndCountry: string =
    this.imageCloudfront + '/' + this.imageCountry;

  menuItems: MenuItem[] = [];
  selectedLinks: { [key: string]: boolean } = {};
  @Output() onOpenSideNav: EventEmitter<any> = new EventEmitter();
  @Output() onOpenSideNavAccount: EventEmitter<any> = new EventEmitter();

  country = this.getCountry().toLowerCase();
  // SVG Logo RE/MAX
  logoREMAXCountrySVG: QRMediaImage = {
    text: 'Logo RE/MAX' + this.imageCountry,
    baseURL: '',
    docFolder: 'assets/logos',
    docName: 'logo-remax-' + this.country,
    docType: Constants.DOCTYPE_SVG,
    alt: 'Logo RE/MAX' + this.imageCountry,
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // SVG Logo RE/MAX Horizontal
  logoREMAXHorizontalCountrySVG: QRMediaImage = {
    text: 'Logo RE/MAX Horizontal',
    baseURL: '',
    docFolder: 'assets/logos',
    docName: 'logo-remax',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Logo RE/MAX Horizontal',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // SVG Icon Arrow Right
  iconArrowRightSVG: QRMediaImage = {
    text: 'Icon Arrow Right',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'arrow-right',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Volver',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // SVG Icon Favoritos
  iconFavoriteOutlineSVG: QRMediaImage = {
    text: 'Icon Favoritos',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'favorite-outlined',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Favoritos',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  iconSaveSearchOutlineSVG: QRMediaImage = {
    text: 'Icon Search',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'bookmark',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Búsquedas',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // SVG Icon Cuenta
  iconPersonOutlineSVG: QRMediaImage = {
    text: 'Icon Cuenta',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'person-outlined',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Cuenta',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // SVG Icon Logout
  iconLogoutSVG: QRMediaImage = {
    text: 'Icon Logout',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'logout',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Cerrar sesión',
    width: 'auto',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Botón: Menú (Mobile)
  configButtonMenu = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  configButtonProfile = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  // Botón: Cuenta
  configButtonAccount = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  /*
  optionsLanguage = [
    { value: 'es', text: 'ES' },
    { value: 'en', text: 'EN' },
  ];
  */
  languageSelected: string = '';
  optionsLanguage: { value: string; text: string }[] = [];
  pathSelected = '';
  baseURL: string = '';
  getURLLocation: Location | undefined;
  urlRouter: string = '';
  menuAccountOpen = false;

  public readonly BUTTON_LOGIN: QRButton = {
    id: 'button-login',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Iniciar sesión',
  };

  userData = {
    name: '',
    lastname: '',
  };

  userLoged = false;
  loading = true;

  isProduction = environment.production;
  private previousUrlRouter: string | null = null;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    private translation: L10nTranslationService,
    public utilsService: UtilsService,
    private router: Router
  ) {}

  openSideNav(): void {
    this.onOpenSideNav.emit();
  }

  openSideNavAccount(): void {
    this.onOpenSideNavAccount.emit();
  }

  checkAndUpdateSelectedLinks(): void {
    const currentUrlRouter = this.router.url;
    if (this.previousUrlRouter !== currentUrlRouter) {
      this.updateSelectedLinks();
      this.previousUrlRouter = currentUrlRouter;
    }
  }

  ngOnInit(): void {
    //Listen for URL changes for active links style
    this.updateSelectedLinks();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkAndUpdateSelectedLinks();
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.pathSelected = window.location.href;
      this.getURLLocation = window.location;
      this.baseURL =
        this.getURLLocation.protocol + '//' + this.getURLLocation.host;
      this.urlRouter =
        this.getURLLocation.protocol +
        '//' +
        this.getURLLocation.host +
        '/' +
        this.getURLLocation.pathname.split('/')[1];
    }

    if (isPlatformBrowser(this.platformId)) {
      //Verificar si hay un usuario logueado
      Auth.currentAuthenticatedUser()
        .then(() => {
          if (
            this.router.url == '/registro' ||
            this.router.url == '/iniciar-sesion' ||
            this.router.url == '/olvide-contraseña'
            /*
        ||
        this.utilsService.isVisibleUserRegister(
          this.node == Constants.NODE_ECUADOR
        ) */
          ) {
            this.router.navigate(['']);
          }
          this.getUserInfo();
        })
        .catch(() => {
          this.loading = false;
          if (
            this.router.url.includes('/cuenta') ||
            this.router.url == '/favoritos' ||
            this.router.url == '/busquedas'
          ) {
            this.router.navigate(['']);
          }
        });
    }

    // Detectar cambio de router
    this.router.events.subscribe(() => {
      if (!this.getURLLocation) return;
      this.urlRouter =
        this.getURLLocation.protocol +
        '//' +
        this.getURLLocation.host +
        '/' +
        this.getURLLocation.pathname.split('/')[1];
    });

    this.menuItems = MENU;

    if (this.node == Constants.NODE_ECUADOR) {
      // En Ecuador se oculta la sección Blog
      this.menuItems = this.menuItems.filter(
        section =>
          section.label != 'home.blog' &&
          section.label != 'home.emprendimientos'
      );
    } else if (this.node == Constants.NODE_URUGUAY) {
      // En Ecuador se oculta la sección Blog
      this.menuItems = this.menuItems.filter(
        section => section.label != 'home.emprendimientos'
      );
    }

    this.languageSelected = this.l10nConfig.defaultLocale.language;
    this.l10nConfig.schema.forEach(e => {
      this.optionsLanguage.push({
        value: e.locale.language,
        text: this.utilsService.getFrendlyLanguageName(e.locale.language),
      });
    });
    // this.translation.onChange().subscribe({
    // 	next: (locale: L10nLocale) => {
    // 	},
    // });
    this.translation.onError().subscribe({
      next: (error: any) => {
        if (error) console.log(error);
      },
    });
  }

  onLogin(): void {
    if (isPlatformBrowser(this.platformId)) {
      const URL = window.location.href;
      localStorage.setItem('returnUrl', URL);
    }
    this.router.navigate(['/iniciar-sesion']);
  }

  isLinkSelected(link: string | undefined): boolean {
    return !!link && this.selectedLinks[link];
  }

  getCountry(): string {
    let country: string;

    switch (this.node) {
      case Constants.NODE_ARGENTINA:
        country = 'Argentina';
        break;
      case Constants.NODE_URUGUAY:
        country = 'Uruguay';
        break;
      case Constants.NODE_ECUADOR:
        country = 'Ecuador';
        break;
      default:
        country = 'Argentina';
        break;
    }
    return country;
  }

  onChangeLang(lang: string): void {
    this.languageSelected = lang;
    for (let i = 0; i < this.l10nConfig.schema.length; i++) {
      if (lang == this.l10nConfig.schema[i].locale.language) {
        this.translation.setLocale(this.l10nConfig.schema[i].locale);
        // i == this.l10nConfig.schema.length;
      }
    }
  }

  isRouteActive(route: string): boolean {
    return this.router.url === route;
  }

  isHomeSelected(): boolean {
    return this.router.url === '/';
  }

  // Detecta cuando se debe mostrar la línea de selección en el item del navbar
  shouldShowLine(activeIsContain: string | undefined): boolean {
    return activeIsContain !== '/' || this.isHomeSelected();
  }

  //redirige a la configuración de cuenta del usuario
  onAccountSettings(): void {
    this.router.navigate(['/cuenta/datos']);
  }

  //Usuario: Cerrar sesión
  onLogout(): void {
    this.loading = true;
    Auth.signOut().then(() => {
      window.location.reload();
      this.userLoged = false;
      this.loading = false;
    });
  }

  //Conseguir data del usuario logueado
  getUserInfo(): void {
    this.loading = true;
    Auth.currentUserInfo()
      .then(response => {
        this.userData.name = response.attributes.given_name;
        this.userData.lastname = response.attributes.family_name;
        this.userLoged = true;
        this.loading = false;
      })
      .catch(error => {
        console.log('Error al conseguir info del user');
        console.log(error);
        this.userLoged = false;
        this.loading = false;
      });
  }

  private updateSelectedLinks() {
    if (isPlatformBrowser(this.platformId)) {
      const pathname = window.location.pathname;
      const linkMap: { [key: string]: (path: string) => boolean } = {
        '/comprar-propiedades': path =>
          path === '/comprar-propiedades' ||
          path === '/listings/buy' ||
          path === '/casas-en-venta' ||
          path === '/departamentos-en-venta' ||
          path.startsWith('/casas-en-venta-en-') ||
          path.startsWith('/terrenos-en-venta-en-') ||
          path.startsWith('/departamentos-en-venta-en-') ||
          path.startsWith('/apartamentos-en-venta-en-'),
        '/alquilar-propiedades': path =>
          path === '/alquilar-propiedades' ||
          path === '/listings/rent' ||
          path === '/casas-en-alquiler' ||
          path === '/departamentos-en-alquiler' ||
          path.startsWith('/casas-en-alquiler-en-') ||
          path.startsWith('/apartamentos-en-alquiler-en-') ||
          path.startsWith('/departamentos-en-alquiler-en-'),
      };
      const menuItems = [
        {
          link: '/',
          activeIsContain: '/',
        },
        {
          link: '/comprar-propiedades',
          activeIsContain: '/comprar-propiedades',
        },
        {
          link: '/alquilar-propiedades',
          activeIsContain: '/alquilar-propiedades',
        },
        {
          link: '/vender',
          activeIsContain: '/vender',
        },
        {
          link: '/emprendimientos',
          activeIsContain: '/emprendimientos',
        },
        {
          link: '/contacto',
          activeIsContain: '/contacto',
        },
      ];

      menuItems.forEach(item => {
        const checkActive = linkMap[item.link];
        this.selectedLinks[item.link] = checkActive
          ? checkActive(pathname)
          : pathname === item.link;
      });
    }
  }
}
