import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@app/core/models/constants.model';
import { QRInput } from '@app/core/models/qr-input.model';
import { Tab } from '@app/core/models/qr-tab.model';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { environment } from '@base/environments/environment';
import { QRInputAutocomplete } from '@app/core/models/qr-input-autocomplete.model';
import { QRButton } from '@app/core/models/qr-button.model';
import { QRDropdown } from '@app/core/models/qr-dropdown.model';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { QRTextarea } from '@app/core/models/qr-textarea.model';
import { QRChip } from '@app/core/models/qr-chip.model';
import { PictureSource } from '@app/core/models/picture-source.model';
import { QRToggle } from '@app/core/models/qr-toggle.model';
import { QrIconComponent } from '@app/components/qr-icon/qr-icon.component';
import { QRInputComponent } from '@app/components/ui/qr-input/qr-input.component';
import { QRTextareaComponent } from '@app/components/ui/qr-textarea/qr-textarea.component';
import { QRInputAutocompleteComponent } from '@app/components/ui/qr-input-autocomplete/qr-input-autocomplete.component';
import { QRButtonComponent } from '@app/components/ui/qr-button/qr-button.component';
import { QRDropdownComponent } from '@app/components/ui/qr-dropdown/qr-dropdown.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { QRChipComponent } from '@app/components/ui/qr-chip/qr-chip.component';
import { QRToggleComponent } from '@app/components/ui/qr-toggle/qr-toggle.component';
import { PictureSourceComponent } from '@app/components/ui/picture-source/picture-source.component';
import { Icon } from '@app/core/models/icon.model';

@Component({
  selector: 'app-qr-comps',
  templateUrl: './qrcomps.component.html',
  styleUrls: ['./qrcomps.component.scss'],
  standalone: true,
  imports: [
    QrIconComponent,
    QRInputComponent,
    QRTextareaComponent,
    QRInputAutocompleteComponent,
    QRButtonComponent,
    QRDropdownComponent,
    QRChipComponent,
    QRToggleComponent,
    PictureSourceComponent,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
  ],
})
export class QRCompsPage implements OnInit {
  readonly ICON_PLUS: Icon = {
    name: QRIcon.NAME_PLUS,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_CHECK: Icon = {
    name: QRIcon.NAME_CHECK,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_ERROR: Icon = {
    name: QRIcon.NAME_ERROR,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_LEFT: Icon = {
    name: QRIcon.NAME_ARROW_LEFT,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_RIGHT: Icon = {
    name: QRIcon.NAME_ARROW_RIGHT,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_MODE_VIEW_LIST: Icon = {
    name: QRIcon.NAME_MODE_VIEW_LIST,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_MODE_VIEW_GRID: Icon = {
    name: QRIcon.NAME_MODE_VIEW_GRID,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_MODE_VIEW_MAP: Icon = {
    name: QRIcon.NAME_MODE_VIEW_MAP,
    color: QRIcon.COLOR_BLACK,
  };

  readonly ICON_OFFICE: Icon = {
    name: QRIcon.NAME_OFFICE,
    color: QRIcon.COLOR_BLACK,
  };

  tab: Tab = {
    tabs: [
      { text: 'Casa' },
      { text: 'Departamento' },
      { text: 'Terrenos y lotes' },
      { text: 'PH' },
      { text: 'Campo' },
      { text: 'Cochera' },
    ],
    size: Tab.SIZE_48_PX,
    tabSelected: 2,
  };

  // Input: Texto
  textInput: QRInput = {
    label: 'Texto',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'text',
    id: 'text',
    placeholder: 'Texto',
    hint: 'Ingrese nombre completo',
    error: 'Ingrese un nombre valido',
  };

  textInputWithValue: QRInput = {
    label: 'Texto',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'text',
    id: 'text',
    placeholder: 'Texto',
    hint: 'Ingrese nombre completo',
    error: 'Ingrese un nombre valido',
  };

  textInputValue: string | undefined = 'Leonardo DiCaprio';

  // Input: Números enteros
  numberIntegerInput: QRInput = {
    label: 'Número entero',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'integer number',
    id: 'numberInteger',
    placeholder: 'Número entero',
    hint: 'Ingrese el precio',
    error: 'Ingrese un precio valido',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  numberIntegerInputWithValue: QRInput = {
    label: 'Número entero',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'integer number',
    id: 'numberInteger',
    placeholder: 'Número entero',
    hint: 'Ingrese el precio',
    error: 'Ingrese un precio valido',
    format: QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS,
  };

  numberIntegerInputValue: number | undefined = 1632;

  // Input: Con limite de caracteres
  limitCharacteresInput: QRInput = {
    label: 'Limite de carácteres',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'limitCharacter',
    id: 'numberInteger',
    limitNumberOfCharacters: 4,
  };

  // Input: Con focus
  focusInput: QRInput = {
    label: 'Focus',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'Focus',
    id: 'Focus',
    isFocus: true,
  };

  // Input Autocomplete
  inputAutocomplete: QRInputAutocomplete = {
    label: 'Color',
    type: QRInput.INPUT_TYPE_TEXT,
    name: 'color',
    id: 'color',
    placeholder: 'Buscar colores',
    options: [
      'Azul',
      'Rojo',
      'Verde',
      'Amarillo',
      'Naranja',
      'Negro',
      'Blanco',
      'Violeta',
      'Marron',
      'Rosa',
      'Celeste',
      'Gris',
    ],
  };

  buttonPrimarySolid: QRButton = {
    id: 'btn-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonPrimarySolidWithIcon: QRButton = {
    id: 'btn-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonPrimarySolidOnlyIcon: QRButton = {
    id: 'btn-primary-solid',
    hierarchy: QRButton.HIERARCHY_PRIMARY_SOLID,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonPrimaryBorder: QRButton = {
    id: 'btn-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonPrimaryBorderWithIcon: QRButton = {
    id: 'btn-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonPrimaryBorderOnlyIcon: QRButton = {
    id: 'btn-primary-border',
    hierarchy: QRButton.HIERARCHY_PRIMARY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonTonalBorder: QRButton = {
    id: 'btn-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonTonalBorderWithIcon: QRButton = {
    id: 'btn-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonTonalBorderOnlyIcon: QRButton = {
    id: 'btn-tonal-border',
    hierarchy: QRButton.HIERARCHY_TONAL_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonTonal: QRButton = {
    id: 'btn-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonTonalWithIcon: QRButton = {
    id: 'btn-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonTonalOnlyIcon: QRButton = {
    id: 'btn-tonal',
    hierarchy: QRButton.HIERARCHY_TONAL,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonGreyBorder: QRButton = {
    id: 'btn-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonGreyBorderWithIcon: QRButton = {
    id: 'btn-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonGreyBorderOnlyIcon: QRButton = {
    id: 'btn-grey-border',
    hierarchy: QRButton.HIERARCHY_GREY_BORDER,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonGrey: QRButton = {
    id: 'btn-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonGreyWithIcon: QRButton = {
    id: 'btn-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonGreyOnlyIcon: QRButton = {
    id: 'btn-grey',
    hierarchy: QRButton.HIERARCHY_GREY,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
    iconTooltipOnly: 'Tooltip de onlyicon',
  };

  buttonGreenSolid: QRButton = {
    id: 'btn-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
  };

  buttonGreenSolidWithIcon: QRButton = {
    id: 'btn-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    value: 'Button',
    icon: QRIcon.NAME_CHECK,
  };

  buttonGreenSolidOnlyIcon: QRButton = {
    id: 'btn-green-solid',
    hierarchy: QRButton.HIERARCHY_GREEN_SOLID,
    size: QRButton.SIZE_48_PX,
    icon: QRIcon.NAME_CHECK,
    iconOnly: true,
  };

  // Dropdown

  dropdown001: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
  };

  dropdown001Selected: string | undefined = 'Verde';

  dropdown002: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
  };

  dropdown002Selected: string | undefined = 'Verde';

  dropdown003: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Colores',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    isMultiple: true,
    label: 'Colores',
  };

  dropdown003Selected: string[] | undefined = ['Verde', 'Gris'];

  dropdown004: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    label: 'Color',
  };

  dropdown004Selected: string = 'Gris';

  dropdown005: QRDropdown = {
    size: QRDropdown.SIZE_56_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
    icon: this.ICON_OFFICE,
    label: 'Color',
  };

  dropdown005Selected: string = 'Gris';

  dropdown006: QRDropdown = {
    size: QRDropdown.SIZE_48_PX,
    placeholder: 'Color',
    options: ['Azul', 'Rojo', 'Verde', 'Amarillo', 'Gris'],
  };

  dropdown006Selected: string | undefined = 'Verde';

  colors: string[] = ['Azul', 'Rojo', 'Verde', 'Amarillo'];

  // Textarea

  textarea: QRTextarea = {
    id: 'textarea',
    label: 'Descripción',
    hint: 'Ingrese una descripción',
    error: 'Ingrese una descripción',
  };

  textareaValue: string | number | undefined = 'Hola, que tal.';

  // Chip

  chipCanBeHovered: QRChip = {
    canBeHovered: true,
  };

  chipCanBeSelected: QRChip = {
    canBeHovered: true,
    canBeSelected: true,
  };

  // Picture and Source
  pictureBase: PictureSource = {
    text: 'Imagen de prueba',
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  //   listingNewProperties: ListingsNewServices[] = [];

  // Dialogs

  //   dialogCreateAccount: QRDialogConfig = {
  //     isDialogOpen: false,
  //     titleFilter: '¡Bienvenido!',
  //     configButtonLeft: {
  //       style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
  //       height: Constants.BUTTON_HEIGHT_48PX,
  //       text: 'Cancelar',
  //       id: 'clean-filters',
  //       selected: false,
  //       isDisabled: false,
  //       changeStyleOnSelected: false,
  //       styleOnSelected: '',
  //     },
  //     configButtonRight: {
  //       style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
  //       height: Constants.BUTTON_HEIGHT_48PX,
  //       text: 'Crear mi cuenta',
  //       id: '@apply',
  //       selected: false,
  //       isDisabled: false,
  //       changeStyleOnSelected: false,
  //       styleOnSelected: '',
  //     },
  //     dinamicComponent: 'invitation-to-register',
  //     isTopbarVisible: true,
  //     isBotbarVisible: false,
  //   };

  toggle001: QRToggle = {
    options: [{ value: 'Comprar' }, { value: 'Alquilar' }, { value: 'Vender' }],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  toggle001OptionSelected: number = 0;

  readonly ICON_PHONE: Icon = {
    name: QRIcon.NAME_PHONE,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  readonly ICON_EMAIL: Icon = {
    name: QRIcon.NAME_EMAIL,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  readonly ICON_WHATSAPP: Icon = {
    name: QRIcon.NAME_WHATSAPP,
    color: QRIcon.COLOR_GREY_WOLF,
  };

  toggle002: QRToggle = {
    options: [
      { value: 'Teléfono', icon: this.ICON_PHONE },
      { value: 'Email', icon: this.ICON_EMAIL },
      { value: 'WhatsApp', icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  toggle002OptionSelected: number = 1;

  toggle003: QRToggle = {
    options: [
      { icon: this.ICON_PHONE },
      { icon: this.ICON_EMAIL },
      { icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  toggle003OptionSelected: number = 1;

  toggle005: QRToggle = {
    options: [
      { value: 'Izquierda', icon: this.ICON_LEFT },
      { value: 'Derecha', icon: this.ICON_RIGHT },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  toggle005OptionSelected: number = 1;

  toggle006: QRToggle = {
    options: [
      { value: 'Lista', icon: this.ICON_MODE_VIEW_LIST },
      { value: 'Grilla', icon: this.ICON_MODE_VIEW_GRID },
      { value: 'Mapa', icon: this.ICON_MODE_VIEW_MAP },
      { value: 'Izquierda', icon: this.ICON_LEFT },
      { value: 'Derecha', icon: this.ICON_RIGHT },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_SOLID,
  };

  toggle006OptionSelected: number = 3;

  toggle007: QRToggle = {
    options: [
      { value: 'Teléfono', icon: this.ICON_PHONE },
      { value: 'Email', icon: this.ICON_EMAIL },
      { value: 'WhatsApp', icon: this.ICON_WHATSAPP },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_BORDER,
  };

  toggle007OptionSelected: number = 1;

  toggle008: QRToggle = {
    options: [
      { value: 'Lista', icon: this.ICON_MODE_VIEW_LIST },
      { value: 'Grilla', icon: this.ICON_MODE_VIEW_GRID },
      { value: 'Mapa', icon: this.ICON_MODE_VIEW_MAP },
      { value: 'Izquierda', icon: this.ICON_LEFT },
      { value: 'Derecha', icon: this.ICON_RIGHT },
    ],
    hierarchy: QRToggle.HIERARCHY_PRIMARY_BORDER,
  };

  toggle008OptionSelected: number = 3;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (environment.production == true) {
      this.router.navigate(['/']); // QR Comps solo habilitado en DEV
    }
  }

  tabChange(tabSelected: number): void {
    console.log('Tab seleccionada: ', tabSelected);
  }

  inputChange(VALUE: string | number | undefined): void {
    if (VALUE) {
      console.log('Input VALUE change:', VALUE);
    } else {
      console.log('Input VALUE change: undefined');
    }
  }

  inputAutocompleteChange(e: string | undefined): void {
    console.log('Seleccionado: ', e);
  }

  dropdownChange(e: string | string[] | undefined): void {
    console.log(e);
  }

  checkboxChange(isChecked: boolean): void {
    console.log(isChecked);
  }

  switchChange(value: MatSlideToggleChange): void {
    console.log(value.checked);
  }

  textareaChange(e: string | number | undefined): void {
    console.log(e);
  }

  chipChange(isSelected: boolean): void {
    console.log(isSelected);
  }

  toggleChange(OPTION_SELECTED: number): void {
    console.log('Opción seleccionada: ', OPTION_SELECTED);
  }
}
