<!-- Fonts -->
<div id="fonts">
  <div class="font-row">
    <h1 class="display-01">Display 01 Bold</h1>
    <h1 class="display-02">Display 01 Black</h1>
  </div>

  <div class="font-row">
    <h1 class="display-03">Display 02 Bold</h1>
    <h1 class="display-04">Display 02 Black</h1>
  </div>

  <div class="font-row">
    <h1 class="heading-01">Heading 01 Bold</h1>
    <h1 class="heading-02">Heading 01 Black</h1>
  </div>

  <div class="font-row">
    <h1 class="heading-03">Heading 02 Medium</h1>
    <h1 class="heading-04">Heading 02 Bold</h1>
  </div>

  <div class="font-row">
    <h1 class="subheading-01">Subheading 01 Medium</h1>
    <h1 class="subheading-02">Subheading 01 Bold</h1>
  </div>

  <div class="font-row">
    <h1 class="subheading-03">Subheading 02 Regular</h1>
    <h1 class="subheading-04">Subheading 02 Medium</h1>
  </div>

  <div class="font-row">
    <h1 class="subheading-05">Subheading 03 Regular</h1>
    <h1 class="subheading-06">Subheading 03 Medium</h1>
    <h1 class="subheading-07">Subheading 03 Bold</h1>
  </div>

  <div class="font-row">
    <p class="paragraph-01">
      Paragraph 01 Regular: Importante casa desarrollada en cuatro plantas, con
      ascensor en todas ellas. Rodeada de espacios verdes con doble acceso,
      principal y de servicio. La calidad de la propiedad es optima con
      materiales de calidad, y gran estado de conservación debido a que todos
      los caños fueron renovados 123456789 !"#$%&/()=?".
    </p>
    <p class="paragraph-02">
      Paragraph 01 Bold: Importante casa desarrollada en cuatro plantas, con
      ascensor en todas ellas. Rodeada de espacios verdes con doble acceso,
      principal y de servicio. La calidad de la propiedad es optima con
      materiales de calidad, y gran estado de conservación debido a que todos
      los caños fueron renovados 123456789 !"#$%&/()=?.
    </p>
  </div>

  <div class="font-row">
    <p class="caption-01">Caption 01 Regular</p>
    <p class="caption-02">Caption 02 Medium</p>
    <p class="caption-03">Caption Bold</p>
  </div>
</div>

<!-- Tab -->
<div id="components">
  <div id="tabs">
    <p class="caption-01">TABS</p>
    <span
      >Pendiente de implementacion nuevo componente SWIPER. Swipper wrapper
      deprecado</span
    >
    <!-- <qr-tabs [config]="tab" (tabChange)="tabChange($event)"></qr-tabs>
      <qr-tabs
        [config]="tab"
        (tabChange)="tabChange($event)"
        [isDisabled]="true"
      ></qr-tabs> -->
  </div>
</div>

<!-- Icon -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Icon</h1>
    <div class="component__wrapper">
      <qr-icon [config]="ICON_PLUS"></qr-icon>
      <qr-icon [config]="ICON_CHECK"></qr-icon>
      <qr-icon [config]="ICON_CHECK"></qr-icon>
      <qr-icon [config]="ICON_LEFT"></qr-icon>
      <qr-icon [config]="ICON_RIGHT"></qr-icon>
      <qr-icon [config]="ICON_MODE_VIEW_LIST"></qr-icon>
      <qr-icon [config]="ICON_MODE_VIEW_GRID"></qr-icon>
      <qr-icon [config]="ICON_MODE_VIEW_MAP"></qr-icon>
      <qr-icon [config]="ICON_OFFICE"></qr-icon>
    </div>
  </div>
</div>

<!-- Inputs -->
<div id="components">
  <!-- Input: Texto -->
  <div class="component">
    <h1 class="component-line__title">Input</h1>
    <div class="component__wrapper">
      <qr-input
        [config]="textInput"
        (valueChange)="inputChange($event)"></qr-input>
      <qr-input
        [config]="textInputWithValue"
        (valueChange)="inputChange($event)"></qr-input>
      <qr-input
        [config]="textInputWithValue"
        (valueChange)="inputChange($event)"
        [(value)]="textInputValue"
        [isValid]="true"></qr-input>
      <qr-input
        [config]="textInput"
        (valueChange)="inputChange($event)"
        [hasError]="true"></qr-input>
      <qr-input
        [config]="textInputWithValue"
        (valueChange)="inputChange($event)"
        [isDisabled]="true"></qr-input>
    </div>
  </div>
  <!-- Input: Número entero -->
  <div class="component">
    <div class="component__wrapper">
      <qr-input
        [config]="numberIntegerInput"
        (valueChange)="inputChange($event)"></qr-input>
      <qr-input
        [config]="numberIntegerInputWithValue"
        (valueChange)="inputChange($event)"></qr-input>
      <qr-input
        [config]="numberIntegerInputWithValue"
        (valueChange)="inputChange($event)"
        [(value)]="numberIntegerInputValue"
        [isValid]="true"></qr-input>
      <qr-input
        [config]="numberIntegerInput"
        (valueChange)="inputChange($event)"
        [hasError]="true"></qr-input>
      <qr-input
        [config]="numberIntegerInputWithValue"
        (valueChange)="inputChange($event)"
        [isDisabled]="true"></qr-input>
    </div>
  </div>
  <!-- Input: Con limite de caracteres  -->
  <div class="component">
    <div class="component__wrapper">
      <qr-input
        [config]="limitCharacteresInput"
        (valueChange)="inputChange($event)"></qr-input>
      <!--
        <qr-input
          [config]="focusInput"
          (valueChange)="inputChange($event)"
        ></qr-input>
        -->
      <!-- Descomentar focus -->
    </div>
  </div>
  <div class="component">
    <div class="component__wrapper">
      <qr-input
        [config]="textInputWithValue"
        [(value)]="textInputValue"
        (valueChange)="inputChange($event)"></qr-input>
      <qr-input
        [config]="numberIntegerInputWithValue"
        [(value)]="numberIntegerInputValue"
        (valueChange)="inputChange($event)"></qr-input>
    </div>
  </div>
</div>

<!-- Textarea -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Textarea</h1>
    <div class="component__wrapper">
      <qr-textarea (textareaChange)="textareaChange($event)"></qr-textarea>
      <qr-textarea
        [config]="textarea"
        (textareaChange)="textareaChange($event)"></qr-textarea>
      <qr-textarea
        [config]="textarea"
        (textareaChange)="textareaChange($event)"
        [value]="textareaValue"
        [isValid]="true"></qr-textarea>
    </div>
    <div class="component__wrapper">
      <qr-textarea
        [config]="textarea"
        [isDisabled]="true"
        (textareaChange)="textareaChange($event)"></qr-textarea>
      <qr-textarea
        [config]="textarea"
        [hasError]="true"
        (textareaChange)="textareaChange($event)"></qr-textarea>
    </div>
  </div>
</div>

<!-- Input Autocomplete -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Input Autocomplete</h1>
    <div class="component__wrapper">
      <qr-input-autocomplete
        [config]="inputAutocomplete"
        (selectedChange)="
          inputAutocompleteChange($event)
        "></qr-input-autocomplete>
    </div>
  </div>
</div>

<!-- Button -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Button</h1>
    <!-- Button: Primary solid -->
    <div class="component__wrapper">
      <qr-button [config]="buttonPrimarySolid"></qr-button>
      <qr-button [config]="buttonPrimarySolidWithIcon"></qr-button>
      <qr-button
        [config]="buttonPrimarySolidWithIcon"
        [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonPrimarySolidOnlyIcon"></qr-button>
    </div>
    <!-- Button: Tonal border -->
    <div class="component__wrapper">
      <qr-button [config]="buttonTonalBorder"></qr-button>
      <qr-button [config]="buttonTonalBorderWithIcon"></qr-button>
      <qr-button
        [config]="buttonTonalBorderWithIcon"
        [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonTonalBorderOnlyIcon"></qr-button>
    </div>
    <!-- Button: Tonal -->
    <div class="component__wrapper">
      <qr-button [config]="buttonTonal"></qr-button>
      <qr-button [config]="buttonTonalWithIcon"></qr-button>
      <qr-button [config]="buttonTonalWithIcon" [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonTonalOnlyIcon"></qr-button>
    </div>
    <!-- Button: Primary border -->
    <div class="component__wrapper">
      <qr-button [config]="buttonPrimaryBorder"></qr-button>
      <qr-button [config]="buttonPrimaryBorderWithIcon"></qr-button>
      <qr-button
        [config]="buttonPrimaryBorderWithIcon"
        [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonPrimaryBorderOnlyIcon"></qr-button>
    </div>
    <!-- Button: Grey border -->
    <div class="component__wrapper">
      <qr-button [config]="buttonGreyBorder"></qr-button>
      <qr-button [config]="buttonGreyBorderWithIcon"></qr-button>
      <qr-button
        [config]="buttonGreyBorderWithIcon"
        [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonGreyBorderOnlyIcon"></qr-button>
    </div>
    <!-- Button: Grey -->
    <div class="component__wrapper">
      <qr-button [config]="buttonGrey"></qr-button>
      <qr-button [config]="buttonGreyWithIcon"></qr-button>
      <qr-button [config]="buttonGreyWithIcon" [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonGreyOnlyIcon"></qr-button>
    </div>
    <!-- Button: Green solid -->
    <div class="component__wrapper">
      <qr-button [config]="buttonGreenSolid"></qr-button>
      <qr-button [config]="buttonGreenSolidWithIcon"></qr-button>
      <qr-button
        [config]="buttonGreenSolidWithIcon"
        [isDisabled]="true"></qr-button>
      <qr-button [config]="buttonGreenSolidOnlyIcon"></qr-button>
    </div>
  </div>
</div>

<!-- Dropdown -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Dropdown</h1>
    <div class="component__wrapper">
      <qr-dropdown
        [config]="dropdown001"
        (onChange)="dropdownChange($event)"></qr-dropdown>
      <qr-dropdown
        [config]="dropdown001"
        [selected]="dropdown001Selected"></qr-dropdown>
      <qr-dropdown
        [config]="dropdown002"
        (onChange)="dropdownChange($event)"></qr-dropdown>
      <qr-dropdown
        [config]="dropdown002"
        (onChange)="dropdownChange($event)"
        [selected]="dropdown002Selected"></qr-dropdown>
    </div>
    <div class="component__wrapper">
      <qr-dropdown
        [config]="dropdown003"
        (onChange)="dropdownChange($event)"></qr-dropdown>
      <qr-dropdown
        [config]="dropdown003"
        (onChange)="dropdownChange($event)"
        [selected]="dropdown003Selected"></qr-dropdown>
    </div>
    <div class="component__wrapper">
      <qr-dropdown
        [config]="dropdown004"
        (onChange)="dropdownChange($event)"
        [selected]="dropdown004Selected"
        [hasError]="true"></qr-dropdown>
      <qr-dropdown
        [config]="dropdown005"
        (onChange)="dropdownChange($event)"
        [selected]="dropdown005Selected"></qr-dropdown>
    </div>
    <div class="component__wrapper">
      <qr-dropdown
        [config]="dropdown006"
        (onChange)="dropdownChange($event)"
        [selected]="dropdown006Selected"></qr-dropdown>
    </div>
  </div>
</div>

<!-- Checkbox -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Checkbox</h1>
    <div class="component__wrapper">
      <mat-checkbox
        class="checkbox--24px"
        [checked]="false"
        color="primary"
        (change)="checkboxChange($event.checked)"
        >Casa</mat-checkbox
      >
      <mat-checkbox
        class="checkbox--24px"
        [checked]="true"
        color="primary"
        (change)="checkboxChange($event.checked)"
        >Departamento</mat-checkbox
      >
      <mat-checkbox
        class="checkbox--24px"
        [checked]="false"
        color="primary"
        (change)="checkboxChange($event.checked)"
        >PH</mat-checkbox
      >
    </div>
  </div>
</div>

<!-- Radio button -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Radio button</h1>
    <div class="component__wrapper">
      @for (color of colors; track color) {
        <mat-radio-button
          class="example-radio-button"
          [value]="color"
          color="primary">
          {{ color }}
        </mat-radio-button>
      }
    </div>
  </div>
</div>

<!-- Switch -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Switch</h1>
    <div class="component__wrapper">
      <mat-slide-toggle
        [checked]="true"
        (change)="switchChange($event)"
        color="primary">
      </mat-slide-toggle>
    </div>
  </div>
</div>

<!-- Chip -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Chip</h1>
    <div class="component__wrapper">
      <qr-chip value="Solo lectura"></qr-chip>
      <qr-chip value="Hover activado" [config]="chipCanBeHovered"></qr-chip>
      <qr-chip
        value="Seleccionable"
        [config]="chipCanBeSelected"
        (onChange)="chipChange($event)"></qr-chip>
      <qr-chip
        value="Seleccionada"
        [config]="chipCanBeSelected"
        [isSelected]="true"
        (onChange)="chipChange($event)"></qr-chip>
    </div>
  </div>
</div>

<!-- Picture and source -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Picture Source</h1>
    <div class="component__wrapper">
      <picture-source
        [config]="pictureBase"
        [src]="'assets/media/webp/indice-remax/cover.webp'"></picture-source>
    </div>
  </div>
</div>

<!-- Dialog -->
<!-- <div id="components">
        <div class="component">
          <h1 class="component-line__title">Dialogs</h1>
          <div class="component__wrapper">
            <a (click)="openDialogCreateAccount()">Abrir dialog 001</a>
            <qr-dialog [data]="dialogCreateAccount"></qr-dialog>
          </div>
        </div>
      </div> -->

<!-- Toggle -->
<div id="components">
  <div class="component">
    <h1 class="component-line__title">Toggle</h1>
    <div class="component__wrapper">
      <qr-toggle
        [config]="toggle001"
        [optionSelected]="toggle001OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
    </div>
    <div class="component__wrapper">
      <qr-toggle
        [config]="toggle001"
        [optionSelected]="toggle001OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      <qr-toggle
        [config]="toggle002"
        [optionSelected]="toggle002OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      <qr-toggle
        [config]="toggle003"
        [optionSelected]="toggle003OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
    </div>
    <div class="component__wrapper">
      <qr-toggle
        [config]="toggle005"
        [optionSelected]="toggle005OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      <qr-toggle
        [config]="toggle006"
        [optionSelected]="toggle006OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
    </div>
    <div class="component__wrapper">
      <qr-toggle
        [config]="toggle007"
        [optionSelected]="toggle007OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
      <qr-toggle
        [config]="toggle008"
        [optionSelected]="toggle008OptionSelected"
        (optionSelectedChange)="toggleChange($event)"></qr-toggle>
    </div>
  </div>
</div>

<!-- Form-->
<!-- <div id="components">
      <div class="forms">
        <div class="form">
          <h1 class="component-line__title">Form</h1>
          <app-form></app-form>
        </div>
      </div>
    </div> -->

<!-- TODO: Date picker -->
<!-- TODO: Tag -->
<!-- TODO: Avatar -->
