<div class="searchbar">
  <locations-searcher
    class="searchbar__locations"
    [selected]="searchDomain.filterGeoList$"
    [isHomeSearcher]="true"
    (locationsSearcherChange)="
      locationsSearcherChange($event)
    "></locations-searcher>
  @if (dropdownListingTypes.options.length > 0) {
    <qr-dropdown
      class="searchbar__dropdown-listing-type"
      [config]="dropdownListingTypes"
      [selected]="dropdownListingTypesSelected"
      [isHomeMode]="true"
      (onChange)="dropdownListingTypesChange($event)"></qr-dropdown>
  } @else {
    <mat-spinner diameter="24"></mat-spinner>
  }
  <button
    class="searchbar__button-search searchbar__button-search--{{ style }}"
    (click)="search()"
    matRipple
    aria-label="Buscador de propiedades">
    <qr-icon [config]="ICON_SEARCH"></qr-icon>
  </button>
</div>
