<div class="map-google-container">
  <!-- Mostramos la cantidad de las propiedades listadas en el mapa -->
  <div id="show-properties-tools">
    <h1
      class="properties-zone-message"
      [innerHtml]="this.searchDomain.reportResult.titleText">
      0
    </h1>
  </div>
  <!-- Mapa -->
  @if (mapLoaded) {
    <google-map
      [options]="mapOptions"
      [zoom]="zoom"
      [center]="innercenter"
      (mapInitialized)="onMapReady($event)"
      (mapClick)="mapClicked($event)">
      @for (marker of markers; track marker.listingId) {
        <qr-marker-google
          (markerClick)="clickedMarker(marker.listingId)"
          [marker]="marker"
          [isZoomClose]="isZoomClose"
          [map]="map" />
      }
    </google-map>
    <!-- Herramientas de dibujo -->
    <div id="drawing-tools">
      <!-- Botón: Dibujar zona -->
      @if (!isDrawing && !isDrawingComplete) {
        <qr-button-with-icon
          (click)="startDrawing()"
          id="draw-zone-button"
          [config]="this.buttonDrawZone">
        </qr-button-with-icon>
      }
      <!-- Botón: Eliminar zona dibujada -->
      @if (isDrawingComplete) {
        <qr-button-with-icon
          (click)="deleteDrawing()"
          id="clean-drawing-button"
          [config]="this.buttonCleanDrawing">
        </qr-button-with-icon>
      }
      <!-- Botón: Cancelar -->
      @if (isDrawing && !isDrawingComplete) {
        <qr-button-with-icon
          (click)="deleteDrawing()"
          id="cancel-drawing-button"
          [config]="this.buttonCancelDrawing">
        </qr-button-with-icon>
      }
    </div>
  }
</div>
