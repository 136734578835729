import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { GeoSearch } from '../geo-search/geo-search.model';
import { ItemParam } from '@app/core/models/ItemParam';
import { SearchDomain } from '../../domain/search.domain';
import { ParamsDomain } from '../../domain/params.domain';
import {
  L10nLocale,
  L10N_LOCALE,
  L10nTranslationModule,
  L10nTranslationService,
} from 'angular-l10n';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrIconComponent } from '../qr-icon/qr-icon.component';
import { Icon } from '../../core/models/icon.model';
import { QRDropdownComponent } from '../ui/qr-dropdown/qr-dropdown.component';
import { QRDropdown } from '../../core/models/qr-dropdown.model';
import { LocationsSearcherComponent } from '../locations-searcher/locations-searcher.component';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'qr-searchbar',
  templateUrl: './qr-searchbar.component.html',
  styleUrls: ['./qr-searchbar.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    L10nTranslationModule,
    QrIconComponent,
    QRDropdownComponent,
    LocationsSearcherComponent,
    MatRippleModule,
  ],
})
export class QrSearchbarComponent {
  @Input() style!: string;
  @Output() onSearch: EventEmitter<void> = new EventEmitter<void>();
  public friendlyListingTypes: ItemParam[] | undefined;

  public dropdownListingTypes: QRDropdown = {
    size: QRDropdown.SIZE_48_PX,
    placeholder: 'list.propertyType',
    options: [],
    isMultiple: true,
  };

  public dropdownListingTypesSelected: string | undefined;

  public readonly ICON_SEARCH: Icon = {
    name: Icon.NAME_SEARCH,
    color: Icon.COLOR_WHITE,
  };

  private casaIDs: number[] = [];
  private departamentosIDs: number[] = [];

  private readonly CASA_ID: number = 100;
  private readonly DEPARTAMENTO_ID: number = 101;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain,
    private translationService: L10nTranslationService
  ) {
    this.loadListingTypes();
  }

  public search(): void {
    this.onSearch.emit();
  }

  public locationsSearcherChange(e: GeoSearch[]): void {
    if (e !== null) {
      this.searchDomain.filterGeoList = e;
    }
  }

  public dropdownListingTypesChange(e: string | string[] | undefined): void {
    const LISTING_TYPES_ID: number[] = [];
    (e as string[])?.forEach((listingType: string) => {
      const LISTING_TYPE_SELECTED: ItemParam | undefined =
        this.friendlyListingTypes?.find((item: ItemParam) => {
          return item.i18nValue == listingType;
        });
      LISTING_TYPES_ID.push(LISTING_TYPE_SELECTED?.id);
    });
    let selection: number[] = LISTING_TYPES_ID;
    const CASA_INDEX: number = LISTING_TYPES_ID.findIndex(
      (option: number) => option == this.CASA_ID
    );
    if (CASA_INDEX >= 0) {
      selection.splice(CASA_INDEX, 1);
      selection = selection.concat(this.casaIDs);
    }
    const DEPARTAMENTO_INDEX: number = selection.findIndex(
      (option: number) => option == this.DEPARTAMENTO_ID
    );
    if (DEPARTAMENTO_INDEX >= 0) {
      selection.splice(DEPARTAMENTO_INDEX, 1);
      selection = selection.concat(this.departamentosIDs);
    }
    this.searchDomain.filterListingType = [...new Set(selection)];
  }

  private loadListingTypes(): void {
    this.friendlyListingTypes = [];
    const LISTING_TYPES: Promise<ItemParam[]> =
      this.paramsDomain.getListingsTypes();
    LISTING_TYPES.then(response => {
      // En la respuesta de listingTypes el dato "casa_comercial" se cambió el nombre a "house_comercial" ya que todos los datos con el nombre "casa" se agregan en ARG como un subfiltro temporal
      const TYPES_TEMPORAL: ItemParam[] = [];
      this.casaIDs = [];
      this.departamentosIDs = [];
      // El filtro temporal sucede acá
      TYPES_TEMPORAL.push(new ItemParam(this.CASA_ID, 'casa', null, 5));
      TYPES_TEMPORAL.push(
        new ItemParam(this.DEPARTAMENTO_ID, 'departamento', null, 1)
      );
      const FILTER_TYPES: ItemParam[] = response.filter(option => {
        if (option.value.includes('casa')) {
          this.casaIDs.push(option.id);
          return false;
        }
        if (option.value.includes('departamento')) {
          this.departamentosIDs.push(option.id);
          return false;
        } else {
          return true;
        }
      });
      this.friendlyListingTypes = TYPES_TEMPORAL.concat(FILTER_TYPES);
      this.friendlyListingTypes = this.friendlyListingTypes.sort(
        (a: ItemParam, b: ItemParam) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        }
      );
      this.friendlyListingTypes.map((item: ItemParam) => {
        item.i18nKey = 'property-type.' + item.value;
        item.i18nValue = this.translationService.translate(
          'property-type.' + item.value
        );
        this.dropdownListingTypes.options.push(item.i18nValue ?? '-');
        return item;
      });
    });
  }
}
