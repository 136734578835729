<div class="header">
  @if (searchDomain.reportResult) {
    <h1
      class="header__summary"
      [innerHtml]="searchDomain.reportResult.titleText">
      0
    </h1>
  }
  <div class="header__options">
    @if (NODE !== NODE_ECUADOR) {
      <button-save-search
        class="header__button-save-search"></button-save-search>
    }
    @if (orderBy$ | async; as orderBy) {
      <qr-dropdown
        class="header__dropdown-order-by"
        [config]="dropdownOrderBy"
        [selected]="dropdownOrderBySelected"
        (onChange)="dropdownOrderByChange($event)"></qr-dropdown>
    }
  </div>
</div>
