@if (units) {
  <ng-container>
    @for (item of unitsKeyValue; track item.key) {
      <div class="desktop">
        @if (item.value && item.value.length > 0) {
          <div id="qr-card-details-emprendimientos-container">
            <!-- DESKTOP -->
            <div class="first-item desktop">
              <h2>
                {{
                  'property-type.' + item.key
                    | translate: locale.language
                    | titlecase
                }}
              </h2>
            </div>
            <!-- DESKTOP -->
            <div class="second-item">
              <p class="subheading-03-bold grey-nero">En venta</p>
              <p class="subheading-03-regular grey-nero">
                <span>{{ item.value.length }}</span>
                <span>Unidades</span>
              </p>
            </div>
            <div class="desktop tabs-container">
              @if (item.key.includes('casa')) {
                <ng-container class="tabs-container">
                  <qr-tabs
                    [config]="this.tabHouses"
                    (tabChange)="tabChangeHouse($event)"></qr-tabs>
                </ng-container>
              }
              @if (item.key.includes('departamento')) {
                <ng-container class="tabs-container">
                  <qr-tabs
                    [config]="this.tabDeptos"
                    (tabChange)="tabChangeDepto($event)"></qr-tabs>
                </ng-container>
              }
              <div class="border-tabs"></div>
            </div>
            <!-- Departamentos desktop -->
            @if (
              item.key === 'departamentos' &&
              this.listingDeptos &&
              this.listingDeptos.length > 0
            ) {
              <ng-container class="desktop">
                @for (unit of listingDeptos; track unit) {
                  <div class="units">
                    <qr-card-details-emprendimientos-item
                      [unit]="unit"></qr-card-details-emprendimientos-item>
                  </div>
                }
              </ng-container>
            }
            <!-- Casas desktop -->
            @if (
              item.key === 'casas' &&
              this.listingHouses &&
              this.listingHouses.length > 0
            ) {
              <ng-container class="desktop">
                @for (unit of listingHouses; track unit) {
                  <div class="units">
                    <qr-card-details-emprendimientos-item
                      [unit]="unit"></qr-card-details-emprendimientos-item>
                  </div>
                }
              </ng-container>
            }
            <!-- Resto propiedades desktop -->
            @if (item.key !== 'casas' && item.key !== 'departamentos') {
              <ng-container class="desktop">
                @for (unit of item.value; track unit) {
                  <div class="units">
                    <qr-card-details-emprendimientos-item
                      [unit]="unit"></qr-card-details-emprendimientos-item>
                  </div>
                }
              </ng-container>
            }
          </div>
        }
      </div>
    }

    <div class="mobile width-full">
      <div id="qr-card-details-emprendimientos-container">
        <!-- MOBILE -->
        <div class="first-item mobile">
          <p class="subheading-01-bold grey-nero mb-16">Unidades</p>
          <qr-button
            [config]="this.buttonOptions"
            (eventClick)="openMenu()"></qr-button>
        </div>

        <div class="second-item mobile">
          <p class="subheading-03-bold grey-nero">En venta</p>
          <p class="subheading-03-regular grey-nero">
            <span>{{ this.mobileList.length }}</span>
            <span>Unidades</span>
          </p>
        </div>
        @for (unit of this.mobileList; track unit) {
          <div class="units">
            <qr-card-details-emprendimientos-item
              [unit]="unit"></qr-card-details-emprendimientos-item>
          </div>
        }
      </div>
    </div>
    @if (this.showMenu) {
      <div id="menu-listings">
        <div id="background" (click)="closeMenu()" aria-hidden="true"></div>
        <div id="menu-container" [ngClass]="{ 'full-height': this.showMenu }">
          @for (item of this.unitsButtonList; track item) {
            <div class="buttons">
              <qr-button
                [config]="item"
                (eventClick)="onChangeListingsType($event)"></qr-button>
            </div>
          }
        </div>
      </div>
    }
  </ng-container>
} @else {
  No hay unidades
}
