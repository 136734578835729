import {
  Component,
  Output,
  Input,
  EventEmitter,
  OnInit,
  OnChanges,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { Constants } from '../../core/constants/constants';
import { SearchDomain } from '../../domain/search.domain';
import {
  ButtonWithIconConfig,
  QrButtonWithIconComponent,
} from '../qr-button-with-icon/qr-button-with-icon.component';
import { GeolocationLatLng } from '../qr-google-autocomplete/qr-google-autocomplete.component';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@base/environments/environment';
import { MarkerMap } from '../../core/models/coords-map.model';
import { QrMarkerGoogleComponent } from './qr-marker-google.component';
import { TruncatePipe } from '../../pipes/truncate.pipe';
declare const google: any;

// More info: https://github.com/angular/components/blob/main/src/google-maps/README.md
@Component({
  selector: 'qr-map-google',
  templateUrl: './qr-map-google.component.html',
  styleUrls: ['./qr-map-google.component.scss'],
  standalone: true,
  imports: [
    GoogleMap,
    GoogleMapsModule,
    QrMarkerGoogleComponent,
    QrButtonWithIconComponent,
    TruncatePipe,
  ],
})
export class QrMapGoogleComponent implements OnInit, OnChanges {
  @Output() onclickmarker: EventEmitter<string> = new EventEmitter<string>();
  @Output() onclick = new EventEmitter<any>();
  @Input() propHoverID = '';
  @Input() zoom = 12;
  map: google.maps.Map | null = null;
  innercenter: GeolocationLatLng = Constants.DEFAULT_CENTER_COORDS;
  isStreetViewEnabled = false;
  pointList: { lat: number; lng: number }[] = [];
  @Output()
  polygonCoordinatesChanged: EventEmitter<any> = new EventEmitter<any>();

  public isZoomClose: boolean = true;
  buttonCleanDrawing: ButtonWithIconConfig = {
    text: 'Borrar zona',
    icon: 'delete',
    height: 40,
    style: 'button-color-primary-border icon-color-primary row-reverse',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  buttonDrawZone: ButtonWithIconConfig = {
    text: 'Delimitar zona',
    icon: 'draw',
    height: 40,
    style: 'button-color-primary-border icon-color-primary row-reverse',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  buttonCancelDrawing: ButtonWithIconConfig = {
    text: 'Cancelar',
    icon: 'close',
    height: 40,
    style: 'button-color-grey-border icon-color-grey row-reverse',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  public renderOptions = {
    suppressMarkers: true,
  };

  // https://developers.google.com/maps/documentation/javascript/controls?hl=es-419#ControlModification
  mapOptions: any = {
    zoomControlOptions: {
      position: 3, // 3 MEANS TOP RIGHT
    },
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    gestureHandling: 'greedy',
  };

  mapLoading = false;
  mapLoaded = false;
  markers: any[] = [];

  polygon: google.maps.Polygon | null = null;
  polyline: google.maps.Polyline | null = null;
  vertices: google.maps.LatLngLiteral[] = [];
  isDrawing: boolean = false;

  /*
  initDrawingManager: Iniciar el sitema de dibujo
  */
  initDrawingManager = (map: any) => {
    const options = {
      drawingControl: false, // Mostrar botones de dibujos default
      drawingControlOptions: {
        drawingModes: ['polygon'], // Modos de dibujo habilitados
      },
      polygonOptions: {
        draggable: false, // Polígono dibujado se puede mover
        editable: false, // Polígono dibujado se puede editar moviendo sus vertices/puntos
      },
      drawingMode: null, // Modo de dibujo
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    // Modificar colores del borde y relleno del polígono
    const polygonOptions = this.drawingManager.get('polygonOptions');
    polygonOptions.fillColor = '#98A6DC';
    polygonOptions.strokeColor = '#002185';
    this.drawingManager.set('polygonOptions', polygonOptions);

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: any) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(paths.getAt(p), 'set_at', () => {
              if (!event.overlay.drag) {
                this.updatePointList(event.overlay.getPath());
              }
            });
            google.maps.event.addListener(paths.getAt(p), 'insert_at', () => {
              this.updatePointList(event.overlay.getPath());
            });
            google.maps.event.addListener(paths.getAt(p), 'remove_at', () => {
              this.updatePointList(event.overlay.getPath());
            });
          }

          this.updatePointList(event.overlay.getPath());
          this.selectedShape = event.overlay;
          this.selectedShape.type = event.type;
        }

        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          /*
           Al completar de dibujar un polígono se sale del modo dibujo,
           para limitar que solo se pueda dibujar un polígono a la vez
          */
          this.drawingManager.setDrawingMode(null);
        }
      }
    );
  };

  public isDrawingComplete: boolean = false;
  private _coords: MarkerMap[] = [];
  private _center: GeolocationLatLng = Constants.DEFAULT_CENTER_COORDS;
  private drawingManager: any;
  private selectedShape: any;
  private _polygonCoordinates: string = '';

  @Input() set coords(value: MarkerMap[]) {
    this._coords = value;
    if (this.map) {
      this.setMarkers();
    }
  }

  get center(): GeolocationLatLng {
    return this._center;
  }

  @Input() set center(coords: GeolocationLatLng) {
    if (typeof coords.lat == 'string' && typeof coords.lng == 'string') {
      this.innercenter = {
        ...{ lat: parseFloat(coords.lat), lng: parseFloat(coords.lng) },
      };
    } else {
      this.innercenter = {
        ...{ lat: coords.lat, lng: coords.lng },
      };
    }
    this._center = this.innercenter;
  }

  constructor(
    public searchDomain: SearchDomain,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.searchDomain.filterPoligono) {
        this._polygonCoordinates = this.searchDomain.filterPoligono;
        const partsFilterPoligono = this.searchDomain.filterPoligono.split(',');
        partsFilterPoligono.forEach(coord => {
          const coordPointList: { lat: number; lng: number } = {
            lng: parseFloat(coord.split(' ').slice(0, 1)[0]),
            lat: parseFloat(coord.split(' ').slice(1)[0]),
          };
          this.pointList.push(coordPointList);
        });
        this.isDrawingComplete = true;
      }
      this.loadMap();
    }
  }

  loadMap() {
    if (this.mapLoaded || this.mapLoading) {
      return;
    }

    if (
      !document.querySelector(`script[src*="maps.googleapis.com/maps/api/js"]`)
    ) {
      this.mapLoading = true;
      const mapsScript = document.createElement('script');
      mapsScript.setAttribute('async', '');
      mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleAPIKey}&libraries=drawing&v=weekly`;
      mapsScript.addEventListener('load', () => {
        this.mapLoaded = true;
        this.mapLoading = false;
      });
      document.head.appendChild(mapsScript);
    } else {
      this.mapLoaded = true;
    }
  }

  clickedMarker(listingId: string): void {
    this.onclickmarker.emit(listingId);
  }

  mapClicked(event: any): void {
    this.onclick.emit(event.coords);
  }

  /*
  updatePointList: Se dispara al completar de dibujar un polígono
  */
  updatePointList(path: any) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(path.getAt(i).toJSON());
    }
    //let polygonCoordinates = '';

    this._polygonCoordinates = '';
    // Parsear array de puntos de coordenadas a un string para el parametro del service
    for (let i = 0; i < this.pointList.length; i++) {
      const point = this.pointList[i];
      if (i != this.pointList.length - 1) {
        this._polygonCoordinates += point.lng + ' ' + point.lat + ',';
      } else {
        this._polygonCoordinates += point.lng + ' ' + point.lat;
      }
    }
    this.isDrawingComplete = true;
    this.polygonCoordinatesChanged.emit(this._polygonCoordinates);
    this.initializeMapAndPolygon();
  }

  /*
  deleteDrawing: Eliminar dibujo de zona
  */
  deleteDrawing() {
    this.isDrawing = false;
    this.isDrawingComplete = false;
    this.stopDrawing();
    this.pointList = [];
    this.initializeMapAndPolygon();
  }

  /*
  stopDrawing: Frenar la herramienta de dibujo en el mapa y obtener cursor de movimiento
  */
  stopDrawing() {
    this.drawingManager.setDrawingMode(null);
    this.isDrawing = false;
    this.isDrawingComplete = false;
    this.pointList = [];
    this.initializeMapAndPolygon();
    this.selectedShape.setMap(null);
  }

  /*
  startDrawing: Activar modo de dibujo de polígono en mapa
  */
  startDrawing() {
    if (this.selectedShape) {
      this.deleteDrawing();
    }
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.isDrawing = true;
    this.isDrawingComplete = false;
  }

  onMapReady(map?: any): void {
    if (map) {
      this.map = map;

      // Define los estilos para ocultar los POI icons
      const MAP_STYLES: google.maps.MapTypeStyle[] = [
        {
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }], // Oculta los nombres de los POIs
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{ visibility: 'off' }], // Oculta los gráficos/iconos de POIs
        },
      ];

      // Aplica los estilos al mapa existente
      this.map?.setOptions({
        styles: MAP_STYLES,
      });

      this.initDrawingManager(this.map);
      this.initializeMapAndPolygon();
      this.setMarkers();

      // Escucha el evento de cambio de zoom
      google.maps.event.addListener(this.map, 'zoom_changed', () => {
        const MAP_ZOOM_LEVEL = this.map?.getZoom();
        if (MAP_ZOOM_LEVEL) {
          this.isZoomClose = MAP_ZOOM_LEVEL > 12; // Cambia según tu definición de "zoom cerca"
          this.updateMarkerStyles(MAP_ZOOM_LEVEL);
        }
      });
    }
  }

  ngOnChanges(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.propHoverID) {
      if (document.getElementById(this.propHoverID)) {
        document.getElementById(this.propHoverID)?.classList.add('hover');
      }
    } else {
      this.removeMarkers();
    }
  }

  private removeMarkers(): void {
    const overlays = document.getElementsByClassName('overlay');
    for (let index = 0; index < overlays.length; index++) {
      const o = overlays[index];
      o.classList.remove('hover');
    }
  }

  private updateMarkerStyles(zoomLevel: number): void {
    const ZOOM_LEVEL = 12;

    this.markers.forEach(marker => {
      const MARKER_ELEMENT = document.getElementById(`marker-${marker.id}`);
      if (MARKER_ELEMENT) {
        if (zoomLevel <= ZOOM_LEVEL) {
          MARKER_ELEMENT.classList.remove('marker');
          MARKER_ELEMENT.classList.add('marker-small');
        } else {
          MARKER_ELEMENT.classList.remove('marker-small');
          MARKER_ELEMENT.classList.add('marker');
        }
      }
    });
  }

  private setMarkers() {
    if (this._coords && this.map !== null) {
      this.removeMarkers();
      this._coords.forEach(marker => {
        if (this.map !== null) {
          this.markers.push(marker);
        }
      });
    }
  }

  private initializeMapAndPolygon() {
    if (this.pointList.length > 0) {
      // Crear el polígono usando los puntos de pointList
      this.polygon = new google.maps.Polygon({
        paths: this.pointList,
        strokeColor: '#002185',
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: '#98A6DC',
        fillOpacity: 0.35,
      });

      // Establecer el polígono en el mapa
      this.polygon?.setMap(this.map);
    } else {
      this.polygon?.setMap(null); // Elimina el polígono del mapa
      this.polygon = null; // Reinicia la referencia al polígono
      this.pointList = [];
    }
  }
}
