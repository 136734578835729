@if (!isButtonSaveSearchAbbreviated) {
  <qr-button
    [config]="BUTTON_SAVE_SEARCH"
    (click)="openSaveSearch()"></qr-button>
} @else {
  <qr-button
    [config]="BUTTON_SAVE_SEARCH_ABBR"
    (click)="openSaveSearch()"></qr-button>
}

<qr-dialog [data]="dialogCreateAccount"></qr-dialog>
<qr-dialog [data]="dialogDataSaveSearch"></qr-dialog>
<qr-dialog [data]="dialogDataNotSaveSearch"></qr-dialog>
