@if(wasFound){
<div>
  <!-- Barra superior -->
  <section id="breadcrumbs">
    <div class="container" id="share-heart">
      <!-- Volver a resultados (Mobile) -->
      <div
        class="container-list"
        fxLayout="row"
        fxShow
        fxHide.gt-sm
        fxLayoutAlign="center center">
        <qr-icon-button
          id="back-to-listing"
          (click)="backToListings()"
          icon="arrow-back-outline"
          [config]="configButtonExpand">
        </qr-icon-button>
        Volver al listado
      </div>
      <!-- Breadcrumbs (Desktop) -->
      <div class="container-breadcrum">
        <!-- Breadcrumb: Tipo de operación -->
        <a [href]="breadcrumbService?.urls?.operationType">
          <li>
            {{ 'list.' + listing.operation?.value | translate:locale.language |
            titlecase }}
          </li>
        </a>
        <span class="separator">></span>
        <!-- Breadcrumb: Tipo de propiedad -->
        <a [href]="breadcrumbService.urls.propertyType">
          <li>
            {{ 'property-type.' + generatePropertyText(listing.type.value) |
            translate: locale.language | titlecase }}
          </li>
        </a>
        @if (listing.geo?.rootLabel) {
        <!-- Breadcrumb: Ubicación (RootLabel/(Subregion/State))-->
        <span class="separator">></span>
        @if (breadcrumbService.urls.rootLabel) {
        <a [href]="breadcrumbService.urls.rootLabel">
          <li>{{ listing.geo?.rootLabel | titlecase }}</li>
        </a>
        } } @if (listing.geo?.countie) {
        <!-- Breadcrumb: Ubicación (Countie/County/Condado/Partido) -->
        <span class="separator">></span>
        @if (breadcrumbService.urls.county) {
        <a [href]="breadcrumbService.urls.county">
          <li>{{ listing.geo?.countie | titlecase }}</li>
        </a>
        } } @if (listing.geo?.citie) {
        <!-- Breadcrumb: Ubicación (Citie/City/Ciudad) -->
        <span class="separator">></span>
        @if (breadcrumbService.urls.city) {
        <a [href]="breadcrumbService.urls.city">
          <li>{{ listing.geo?.citie | titlecase }}</li>
        </a>
        } } @if (listing.geo?.neighborhood) {
        <!-- Breadcrumb: Ubicación (Neighborhood/Barrio) -->
        <span class="separator">></span>
        <a [href]="breadcrumbService.urls.neighborhood">
          <li>{{ listing.geo?.neighborhood | titlecase }}</li>
        </a>
        } @if (listing.geo?.privateCommunityId) {
        <!-- Breadcrumb: Ubicación (privatecommunitie/privateCommunity/Barrio privado) -->
        <span class="separator">></span>
        @if (breadcrumbService.urls.privateCommunity) {
        <a [href]="breadcrumbService.urls.privateCommunity">
          <li>{{ listing?.geo?.privateCommunityId }}</li>
        </a>
        } }

        <span class="separator">></span>
        <!-- Título -->
        <li id="title">{{listing.title}}</li>
      </div>
      <div class="buttons-container"></div>
    </div>
  </section>

  <div id="multimedia-wrapper" class="container">
    <section class="title-multimedia">
      <div class="title-multimedia_container">
        <div id="title">
          <h1>{{ listing.title }}</h1>
        </div>
        <div class="social-buttons">
          @if (utilsService.isVisibleUserRegister(country)) {
          <qr-icon-button
            [icon]="isFavorite ? 'favorite-filled' : 'favorite-outlined'"
            (click)="addFavorite()"
            [config]="configButtonFavorite"></qr-icon-button>
          } @if (false) {
          <qr-icon-button
            icon="share-outlined"
            [config]="configButtonShere"></qr-icon-button>
          }
        </div>
      </div>

      <div class="multimedia-buttons">
        <!-- Botonera: Multimedia-->
        <qr-multimedia-buttons
          [style]="multimediaStyle"
          (onselect)="multimediaSelect($event)">
        </qr-multimedia-buttons>
      </div>
    </section>
    <!-- Multimedia -->
    @if (isBrowser) {
    <section id="multimedia">
      <!-- Galeria de fotos -->
      @if (multimediaSelected === 'photo') {
      <qr-photos-detail [photos]="listing.photos"></qr-photos-detail>
      }
      <!-- Video -->
      @if (multimediaSelected === 'video') { @defer( on idle ){
      <qr-video-container
        [video]="listing.video || ''"
        [title]="listing.title || ''">
      </qr-video-container>
      }@placeholder {
      <span> </span>
      } } @defer( on idle ){
      <qr-photo-panorama [config]="photos360Config"></qr-photo-panorama>
      }
    </section>
    }
  </div>

  <div class="container">
    <div fxFlex fxlayout.gt-sm="row" fxLayout.lt-md="column" class="row">
      <!-- Columna 1-->
      <div fxFlex fxFlex="66" fxFlex.lt-md="100" id="column-1">
        <!-- Datos básicos -->
        <qr-card-info-prop
          [data]="listing"
          [isEntrepreneurship]="false"></qr-card-info-prop>
        <!-- Descripción -->
        <qr-card-details-prop [data]="listing"></qr-card-details-prop>
        <!-- Mapa -->

        @defer( on idle ){
        <div id="card-map">
          <div id="content">
            <h2>
              {{ 'listing-detail.location' | translate:locale.language |
              titlecase }}
            </h2>
            <p id="ubication-text">
              {{ listing.displayAddress + ', ' + listing.geo?.label }}
            </p>
          </div>
          @if (listing.location?.coordinates) {
          <div id="map">
            <div id="map-wrapper">
              @if (isStaticMapModeEnabled) {
              <img
                id="map-static-img"
                alt="Mapa"
                (click)="enableEmbedMap()"
                [src]="sanitizeMapStaticURL" />
              } @if (!isStaticMapModeEnabled) {
              <iframe
                id="map-embed"
                [src]="sanitizeMapEmbedURL"
                width="100%"
                [height]="staticMapHeight"
                frameborder="0"
                style="border: 0"
                allowfullscreen></iframe>
              }
            </div>
          </div>
          }
        </div>
        }
      </div>
      <!-- Columna 2 -->

      @defer( on idle ){
      <div fxFlex fxFlex="34" fxFlex.lt-md="100" id="column-2">
        @if (utilsService.isVisibleAdvertisingBanners(country) && (operationType
        === OPERATION_TYPE_SALE || operationType === OPERATION_TYPE_BUY) &&
        listing.showLendarBanner) {
        <banner-lendar-garantor
          [config]="bannerLendar"
          [data]="listing"></banner-lendar-garantor>
        } @if (utilsService.isVisibleAdvertisingBanners(country) &&
        operationType === OPERATION_TYPE_RENT && !listing.showLendarBanner) {
        <banner-lendar-garantor
          [config]="bannerGarantor"
          [data]="listing"></banner-lendar-garantor>
        } @if (!isAgentLoading) { @if (this.country === nodeUruguay &&
        !(operationType === OPERATION_TYPE_RENT)) {
        <banner-itau [data]="listing" [config]="bannerItau"></banner-itau>
        }

        <card-contact-details
          [data]="listing"
          [agentData]="agent"></card-contact-details>
        }
        <!-- Oficina -->
        @if (agent) {
        <a class="agent-link" [href]="'/' + agent.office.slug" role="button">
          <qr-card-info-office [data]="agent.office"></qr-card-info-office>
        </a>
        }
      </div>

      }
    </div>
  </div>

  <!-- PREFOOTER + BANNERS-->
  @if (isPrefooterVisible) { @defer( on viewport ){
  <section class="container prefooter__container">
    <!-- Validación desde utilsService para ver en que país existe -->
    @if (!isAgentLoading && !isRelatedPropertiesLoading &&
    relatedProperties.length > 0 &&
    utilsService.isVisiblePrefooterSearchRelations(country)) {
    <h2 class="prefooter__title-section">Búsquedas relacionadas</h2>
    <div class="prefooter__swiper">
      <swiper-container appSwiper init="false" [config]="swiperConfig">
        @for (prop of relatedProperties; track prop) {
        <swiper-slide>
          <qr-card-prop
            class="swiper-slide"
            [data]="prop"
            [isEntrepreneurship]="false"
            [isAgentThumbnailVisible]="true"
            [isButtonFavoriteVisible]="false"
            [hasSwiperImages]="false"
            [forcedAgent]="isForcedAgent ? agent : undefined"></qr-card-prop>
        </swiper-slide>
        }
      </swiper-container>
      <qr-button-x
        class="btn-nav btn-nav--prev"
        [config]="buttonLeftNavigationSwiper"></qr-button-x>
      <qr-button-x
        class="btn-nav btn-nav--next"
        [config]="buttonRightNavigationSwiper"></qr-button-x>
    </div>
    }
  </section>
  }@placeholder {
  <span> </span>
  } } @defer( on viewport ){
  <!-- DISCLAIMER -->
  <div fxLayoutAlign="center center">
    <div class="container disclaimer-container">
      @if (country !== 'ec') {
      <!-- Disclaimer: General-->
      @if (isBrowser) {
      <qr-disclaimer type="listing-detail"></qr-disclaimer>
      }
      <!-- Disclaimer: Accesible para personas con discapacidades físicas: -->
      @if (!listing.reducedMobility) {
      <qr-disclaimer type="reduced-movility-no"> </qr-disclaimer>
      } @else {
      <qr-disclaimer type="reduced-movility-yes"> </qr-disclaimer>
      } }
    </div>
  </div>
  }@placeholder {
  <span> </span>
  }
</div>
}

<!-- Publicación no disponible -->
@if (isPropertyNotAvailable) {
<div class="container">
  <div
    fxFlex
    fxLayout.lt-md="column"
    fxLayout.gt-sm="row"
    fxLayoutAlign="center center"
    id="no-available-container">
    @if (isPropertyNotAvailable === 601) {
    <div id="no-available-sold-img" class="no-available-img"></div>
    } @if (isPropertyNotAvailable === 602 || isPropertyNotAvailable === 603) {
    <div id="no-available-rented-img" class="no-available-img"></div>
    }
    <div>
      <div class="no-available">
        <h2>La propiedad ya no se encuentra disponible</h2>
      </div>
      <div class="no-available">
        <p>Encontra más propiedades utilizando el buscador en nuestra</p>
        <a href="/" role="button">página principal</a>
      </div>
    </div>
  </div>
</div>
}

<qr-dialog [data]="dialogData"></qr-dialog>
