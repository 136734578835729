<!-- Navbar -->
<div class="filters">
  <div class="filters__wrapper">
    @if (searchDomain.filterOfficeId === '') {
      <div class="filters__desktop">
        <!-- Filtro: Ubicación -->
        <locations-searcher
          class="filters__ubication"
          [selected]="searchDomain.filterGeoList$"
          (locationsSearcherChange)="
            filterUbicationChanged($event)
          "></locations-searcher>
        <!-- Filtro: Tipo de propiedad -->
        @if (dataListingsTypes$ | async; as data) {
          <qr-dropdown-select
            class="filter_select"
            [id]="DROPDOWN_ID_PROPERTY_TYPE"
            (click)="toggleDropdown(DROPDOWN_ID_PROPERTY_TYPE)"
            [options]="data"
            [config]="dropdownSelectPropertyType"
            [linkedDropdown]="dropdownPropertyType.isOpen">
          </qr-dropdown-select>
        } @else {
          <skeleton-loader class="loader__dropdown"></skeleton-loader>
        }
        <!-- Filtro: Operación -->
        @if (!isEntrepreneurship) {
          @if (dataOperation$ | async; as data) {
            <qr-dropdown-select
              class="filter_select"
              [id]="DROPDOWN_ID_OPERATION_TYPE"
              (click)="toggleDropdown(DROPDOWN_ID_OPERATION_TYPE)"
              [options]="data"
              [config]="dropdownSelectOperation"
              [linkedDropdown]="this.dropdownOperation.isOpen">
            </qr-dropdown-select>
          } @else {
            <skeleton-loader class="loader__dropdown"></skeleton-loader>
          }
        }
        <!-- Filtro: Stage -->
        @if (isEntrepreneurship) {
          @if (dataStage$ | async; as data) {
            <qr-dropdown-select
              class="filter_select"
              [id]="DROPDOWN_ID_STAGE"
              (click)="toggleDropdown(DROPDOWN_ID_STAGE)"
              [options]="data"
              [config]="dropdownSelectStage"
              [linkedDropdown]="this.dropdownStage.isOpen">
            </qr-dropdown-select>
          } @else {
            <skeleton-loader class="loader__dropdown"></skeleton-loader>
          }
        }
        <!-- Filtro: Precio -->
        <qr-dropdown-select
          class="filter_select"
          id="select-price"
          (click)="toggleDropdown('select-price')"
          [config]="dropdownSelectPrice"
          [linkedDropdown]="this.dropdownPrice.isOpen">
        </qr-dropdown-select>
      </div>
      <qr-button [config]="buttonFilters" (click)="openFilters()"></qr-button>
      @if (NODE !== NODE_ECUADOR) {
        <button-save-search
          class="button-save-search-abbr"
          [isButtonSaveSearchAbbreviated]="true"></button-save-search>
      }
    } @else {
      <div class="filters__office">
        <div class="filters__office-icon"></div>
        <p>{{ searchDomain.officeName }}</p>
        <div
          class="filters__office-btn-clear"
          matRipple
          matTooltip="Quitar filtro de oficina"
          aria-hidden="true"
          (click)="clearAllFilters()">
          <div class="filters__office-icon-clear"></div>
        </div>
      </div>
    }

    <!-- <ng-template #office>
      <div class="filters__office">
        <div class="filters__office-icon"></div>
        <p>{{ searchDomain.officeName }}</p>
        <div
          class="filters__office-btn-clear"
          matRipple
          matTooltip="Quitar filtro de oficina"
          aria-hidden="true"
          (click)="clearAllFilters()">
          <div class="filters__office-icon-clear"></div>
        </div>
      </div>
    </ng-template> -->
  </div>
</div>

<!-- Diálogo: Filtros -->
<qr-dialog
  [data]="dialogFilters"
  (onButtonLeft)="clearAllFilters()"
  (onButtonRight)="dialogFiltersButtonRight()">
</qr-dialog>

<!-- Desplegable: Tipo de propiedad -->
@if (dataListingsTypes$ | async; as data) {
  <qr-dropdown-content
    [options]="data"
    [selected$]="searchDomain.filterListingType$"
    [config]="dropdownPropertyType"
    (onClose)="filterListingTypeChanged($event)"
    (onchange)="filterListingTypeUpdateData($event)">
  </qr-dropdown-content>
}

<!-- Desplegable: Operación -->
@if (!isEntrepreneurship) {
  @if (dataOperation$ | async; as data) {
    <qr-dropdown-content
      [options]="data"
      [selected$]="searchDomain.filterOperation$"
      [config]="dropdownOperation"
      (onClose)="filterOperationChanged($event)">
    </qr-dropdown-content>
  }
}

<!-- Desplegable: Etapa / Emprendimientos -->
@if (isEntrepreneurship) {
  @if (dataStage$ | async; as data) {
    <qr-dropdown-content
      [options]="data"
      [selected$]="searchDomain.filterStage$"
      [config]="dropdownStage"
      (onClose)="filterStageChanged($event)">
    </qr-dropdown-content>
  }
}

<!-- Desplegable: Precio -->
@if (dataCurrencyTypes$ | async; as data) {
  <qr-dropdown-content
    [options]="data"
    [selected$]="searchDomain.filterPrice$"
    [config]="dropdownPrice"
    (onClose)="filterPriceChanged($event)">
  </qr-dropdown-content>
}
<!-- Desplegable: Ordenar por -->
@if (dataOrderBy$ | async; as data) {
  <div>
    <qr-dropdown-content
      [options]="data"
      [selected$]="searchDomain.filterOrderBy$"
      [config]="dropdownOrderBy"
      (onClose)="dataOrderBy($event)">
    </qr-dropdown-content>
  </div>
}

<!-- Toggle: Modos de vista -->
@if (searchDomain.viewMode && isBrowser) {
  <div class="view-mode">
    <button
      class="view-mode__btn"
      [class.view-mode__btn--selected]="
        searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST
      "
      matRipple
      matTooltip="Ver listado"
      matTooltipPosition="left"
      (click)="viewModeChange(LISTINGS_VIEW_MODE_LIST)">
      <qr-icon [config]="iconList"></qr-icon>
    </button>
    @if (isGridViewModeEnable) {
      <button
        class="view-mode__btn"
        [class.view-mode__btn--selected]="
          searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID
        "
        matRipple
        matTooltip="Ver grilla"
        matTooltipPosition="left"
        (click)="viewModeChange(LISTINGS_VIEW_MODE_GRID)">
        <qr-icon [config]="iconGrid"></qr-icon>
      </button>
    }
    <button
      class="view-mode__btn"
      [class.view-mode__btn--selected]="
        searchDomain.viewMode === LISTINGS_VIEW_MODE_MAP
      "
      matRipple
      matTooltip="Ver mapa"
      matTooltipPosition="left"
      (click)="viewModeChange(LISTINGS_VIEW_MODE_MAP)">
      <qr-icon [config]="iconMap"></qr-icon>
    </button>
  </div>
}
