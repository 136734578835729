@if (office && found) {
<div id="office-detail-container">
  <div id="banner">
    <div id="banner-photo"></div>
  </div>
  <div class="container">
    <div id="first-column">
      <!-- Oficina -->
      <div>
        <qr-card-info-office [data]="office" [isImgTop]="true">
        </qr-card-info-office>
      </div>
      <!-- Ubicación  -->
      <div class="ubication-container">
        <h2 class="title">
          {{ 'officeDetail.ubication' | translate:locale.language | titlecase }}
        </h2>
        @if (office && office.address) {
        <div class="address-container">
          <h4>{{office.address}}</h4>
        </div>
        } @if (this.office.location?.coordinates) { @defer( on idle ){
        <div id="map">
          <div id="map-wrapper">
            @if (isStaticMapModeEnabled) {
            <img
              id="map-static-img"
              alt="Foto"
              (click)="enableEmbedMap()"
              [src]="sanitizeMapStaticURL" />
            } @if (!isStaticMapModeEnabled) {
            <iframe
              id="map-embed"
              [src]="sanitizeMapEmbedURL"
              width="100%"
              [height]="staticMapHeight"
              frameborder="0"
              style="border: 0"
              allowfullscreen></iframe>
            }
          </div>
        </div>
        } }
      </div>
      <!-- Disclaimer (Argentina)-->
      @if (node === 'ar') {
      <qr-biography-card
        id="disclaimer"
        title="agentDetail.disclaimer"
        [hideButton]="true"
        data="disclaimer.office"
        class="biography-container">
      </qr-biography-card>
      }
    </div>
    <!-- Columna 2 -->
    <div id="second-column">
      <!-- Propiedades -->
      <div id="properties">
        <div id="tabs">
          <qr-tabs
            [config]="tabWithIcon"
            (tabChange)="tabChange($event)"></qr-tabs>
        </div>
        <h4 id="profile-content-subtitle">
          {{ totalItems }} {{ tabWithIcon.tabSelected === 0 ? (totalItems === 1
          ? 'propiedad publicada por la oficina' : 'propiedades publicadas por
          la oficina') : (totalItems === 1 ? 'emprendimiento publicado por la
          oficina' : 'emprendimientos publicados por la oficina') }}
        </h4>
        <!-- Swiper de propiedades -->
        <div id="properties-swiper">
          <swiper-container [config]="swiperProperties" appSwiper init="false">
            @for (prop of listingsAndEntrepreneurship; track prop; let i =
            $index) {
            <swiper-slide class="swiper__slide">
              <qr-card-property
                [data]="prop"
                [isImageSwiper]="true"
                [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"></qr-card-property>
            </swiper-slide>
            }

            <!-- Botón: Ver más -->
            <swiper-slide>
              <div id="properties-view-more">
                <qr-button
                  [config]="configButtonViewMore"
                  (eventClick)="goToListingByOfficeid()">
                </qr-button>
              </div>
            </swiper-slide>
          </swiper-container>
          <!-- Navegación de swiper -->
          <div class="button-prev-1" matRipple></div>
          <div class="button-next-1" matRipple></div>
        </div>
      </div>
      <!-- Corredor público inmobiliario  -->
      <!-- Para EC los brokers vienen como auctioneerOffices -->
      @if (auctioneerOffices && auctioneerOffices.length>0) {
      <div>
        <h3 class="title-member">
          {{node !== 'ec' ? 'Corredor público inmobiliario' : 'Broker'}}
        </h3>
        <div class="office-members">
          @for (auctioneerOffice of auctioneerOffices; track auctioneerOffice;
          let i = $index) {
          <qr-card-office-member
            [data]="auctioneerOffice"></qr-card-office-member>
          }
        </div>
      </div>
      }
      <!-- Corredor asociado  -->
      @if (node !== 'ec') { @if (auctioneerAssociates &&
      auctioneerAssociates.length>0) {
      <div>
        <h4 class="title-member">Corredor asociado</h4>
        <div class="office-members">
          @for (auctioneerAssociate of auctioneerAssociates; track
          auctioneerAssociate; let i = $index) {
          <qr-card-office-member
            [data]="auctioneerAssociate"></qr-card-office-member>
          }
        </div>
      </div>
      } }
      <!-- Equipo  -->
      @if (teams && teams.length > 0) {
      <div>
        <h3 class="title-member" id="title-team">Equipo</h3>
        <div class="team-staff-members">
          @if (seeTeamsShort) { @for (team of teamsShort; track team; let i =
          $index) {
          <qr-card-office-member [data]="team"></qr-card-office-member>
          } } @if (!seeTeamsShort) { @for (team of teams; track team; let i =
          $index) {
          <qr-card-office-member [data]="team"></qr-card-office-member>
          } }
        </div>
        <qr-button
          class="more-button"
          [config]="configButtonAllFeatured"
          (eventClick)="onClickSeeAll()">
        </qr-button>
      </div>
      }
      <!-- Staff administrativo -->
      @if (staffs && staffs.length>0) {
      <div>
        <h3 class="title-member">Staff administrativo</h3>
        <div class="team-staff-members">
          @for (staff of staffs; track staff; let i = $index) {
          <qr-card-office-member [data]="staff"></qr-card-office-member>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>
}
